export enum SectionTypeEnum {
  CARDS_SECTION = "section-cards",
  DATAS_SECTION = "section-datas",
  CONTENTS_SECTION = "section-contents",
  LINKS_SECTION = "section-links",
  LOGOS_SECTION = "section-logos",
  RATINGS_SECTION = "section-ratings",
  SIMPLES_SECTION = "section-simples",
  TITLES_SECTION = "section-titles",
  MOBILE_APP_BANNER = "mobile-app-banner",
  NAVIGATION_BAR = "navigation-bar",
  FOOTER = "footer",
  ECOLOGY_BANNER = "ecology-banner",
  NEWSLETTERS_BANNER = "newsletters-banner",
  TRANSFERTS_SECTION = "section-transferts",
  IMAGE_BANNERS = "image-banners",
  MAPS_CARD_SECTION = "section-maps-cards",
  FOLDABLE_SECTION = "section-foldables",
  LARGE_CARDS_SECTION = "section-large-cards",
  PRE_FOOTER = "pre-footer",
  PRE_FOOTER_SINGLE = "pre-footer-single",
  TWO_COLOR_COLUMNS_SECTION = "section-color-columns",
  RSE_TITLES_SECTION = "section-rse-titles",
  SECTION_404 = "404",
  CONTACT_US = "section-contact-us",
}

export enum SectionStrapiTypeEnum {
  SINGLE = "single",
  COLLECTION = "collection",
}
