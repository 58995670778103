import React from "react";
import tw from "tailwind-styled-components";
import { HeaderSection } from "../../../components";
import { PageVariables } from "../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { textCompiler } from "../../../services/textCompiler";
import { SimplesSectionType } from "../../../types";

interface SimpleSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: SimplesSectionType;
  hideHeader?: boolean;
  firstSection?: boolean;
}

export function SimpleSection(props: SimpleSectionProps): JSX.Element {
  const { data, variables, hideHeader, firstSection } = props;

  return (
    <Main>
      {!hideHeader && (
        <HeaderSection
          level={firstSection ? "h1" : "h2"}
          title={textCompiler(data.title, variables)}
          text={textCompiler(data.text, variables)}
        />
      )}
      {data && data.image && data.image.data && data.image.data.attributes && (
        <ImageContainer>
          <ImageStyled
            src={getStrapiMediaUrl(data.image.data.attributes)}
            alt={data.image.data.attributes.name}
          />
        </ImageContainer>
      )}
      {data.video?.url ? (
        <VideoContainer>
          <div className="sm:hidden">
            <iframe width="385" height="220" src={data.video.url} />
          </div>
          <div className="hidden sm:flex">
            <iframe width="785" height="453" src={data.video.url} />
          </div>
        </VideoContainer>
      ) : null}
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lm:px-0 
`;

const ImageContainer = tw.div`
w-full
h-full
`;

const ImageStyled = tw.img`
  w-full
  h-full
  rounded
`;

const VideoContainer = tw.div`
flex
justify-center
mt-3
`;
