/* eslint-disable indent */
import React from 'react';
import tw from 'tailwind-styled-components';

const IFRAME_ID = 'contact-us';

export interface ContactIFrameProps {}

export function ContactIFrame(): JSX.Element {
  return (
    <Iframe
      id={IFRAME_ID}
      name='booker'
      src='/contact-app/'
      loading='lazy'
      scrolling='auto'
      frameBorder='0'
    ></Iframe>
  );
}

const Iframe = tw.iframe`
  w-96 lg:w-144
  h-144
`;
