import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import SwiperCore, { Navigation, SwiperOptions } from "swiper";
import { Swiper as ReactSwiper } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { NavigationOptions } from "swiper/types/components/navigation";
import tw from "tailwind-styled-components";
import { Image } from "../Image";
import { PaginationBadge } from "../Pagination";

interface SwiperProps extends SwiperOptions {
  className?: string;
  children?: ReactNode;
  swiperclassname?: string;
}

const SWIPER_BREAKPOINTS = {
  0: {
    slidesPerView: 1,
  },
  500: {
    slidesPerView: 1,
  },
  1000: {
    slidesPerView: 1,
  },
  1300: {
    slidesPerView: 1,
  },
};

export function Swiper(props: SwiperProps): JSX.Element {
  const { className, children, swiperclassname } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedSlides, setDisplayedSlides] = useState(0);
  const [lengthSlides, setLengthSlides] = useState<number>(0);

  SwiperCore.use([Navigation]);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  function isAtSwiperEnd(): boolean {
    return currentIndex === lengthSlides - displayedSlides;
  }

  function getDisplayedSlides(breakpoint: string) {
    setDisplayedSlides(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (SWIPER_BREAKPOINTS as any)[breakpoint]?.slidesPerView ?? 1
    );
  }

  return (
    <>
      <SliderContainer>
        <LeftArrow style={{}} ref={navigationPrevRef}>
          <ImageContainer>
            <ResponsiveImageStyled src="/left-arrow-carousel.svg" alt="left" />
          </ImageContainer>
        </LeftArrow>
        <RightArrow style={{}} ref={navigationNextRef}>
          <ImageContainer>
            <ResponsiveImageStyled
              src="/right-arrow-carousel.svg"
              alt="right"
            />
          </ImageContainer>
        </RightArrow>
        <ReactSwiperStyled
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            if (swiper && swiper.params && swiper.params.navigation) {
              (swiper.params.navigation as NavigationOptions).prevEl =
                navigationPrevRef.current;
              (swiper.params.navigation as NavigationOptions).nextEl =
                navigationNextRef.current;
            }
          }}
          onAfterInit={(swiper) => {
            setLengthSlides(swiper.slides.length);
            getDisplayedSlides(swiper.currentBreakpoint);
          }}
          onBreakpoint={(swiper) => {
            getDisplayedSlides(swiper.currentBreakpoint);
          }}
          slidesPerView={"auto"}
          spaceBetween={16}
          onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
          breakpoints={SWIPER_BREAKPOINTS}
          watchOverflow={true}
          {...props}
          className={swiperclassname}
        >
          {children}
        </ReactSwiperStyled>
      </SliderContainer>
      <PaginationBadgeMargin
        badgeSize={4}
        length={lengthSlides}
        focused={isAtSwiperEnd() ? lengthSlides - 1 : currentIndex}
      />
    </>
  );
}

const ReactSwiperStyled = styled(ReactSwiper)`
  .swiper-button-disabled {
    opacity: 0 !important;
  }
`;

const SliderContainer = tw.div`
  w-full
  h-full
`;

const LeftArrow = tw.div`
  absolute
  right-auto
  top-1/3
  -left-3
  lg:left-[-1.5rem]
  w-12
  h-12
  rounded-full
  border
  hover:border-black-alfred
  border-alfred-lightgray
  bg-white
  cursor-pointer
  shadow-md
  z-40
`;

const RightArrow = tw.div`
  absolute
  w-12
  h-12
  left-auto
  -right-3
  lg:right-[-1.5rem]
  top-1/3
  rounded-full
  border
  hover:border-black-alfred
  border-alfred-lightgray
  bg-white
  cursor-pointer
  shadow-md
  z-40
`;

const ImageContainer = tw.div`w-full h-full`;

const ResponsiveImageStyled = tw(Image)`
mx-auto
my-4
w-4
h-4
`;

const PaginationBadgeMargin = tw(PaginationBadge)`
  mt-8
  mb-4
  lg:flex
  flex
  justify-center
`;
