import { Dispatch, useState } from 'react';
import { IS_SSR } from '../services/next';

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<T>] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (!IS_SSR) {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      }
      return initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (!IS_SSR) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
