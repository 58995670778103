var seedrandom = require('seedrandom');

/* eslint-disable @typescript-eslint/no-explicit-any */
function getValueByKey(object: any, path: string, defaultValue = null) {
  return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
}

export function textCompiler(text: string, variables?: any): string {
  if (!text || text.length === 0) return text;
  return text.replace(/\{.*?\}/g, (match: string) => {
    const key = match.replace(/[{}]/g, '');
    const res = key.split('|');
    if (res.length > 1) {
      const rand =
        Math.trunc(seedrandom(variables ? variables : '')() * 100) % res.length;
      return res[rand];
    }
    if (!variables) return match;
    return getValueByKey(variables, key) ?? '';
  });
}


