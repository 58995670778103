import React from 'react';
import tw from 'tailwind-styled-components';
import { ButtonBase } from './ButtonBase';
import { ButtonLoader } from './ButtonLoader';

export interface ButtonPrimaryProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  icon?: string;
  color: string;
  styled?: string;
  className?: string;
}

export function ButtonPrimary(props: ButtonPrimaryProps): JSX.Element {
  const { children, styled, className, color, isLoading, ...remainingProps } =
    props;

  return (
    <ButtonPrimaryStyled
      {...remainingProps}
      className={`py-3 px-6 text-sm text-white flex items-center justify-center bg-gradient-to-r hover:opacity-80 hover:cursor-pointer ${
        color == 'green' ? 'from-green' : 'from-blue'
      } ${
        color == 'green' ? 'to-greenLight' : 'to-blueLight'
      } ${styled} ${className} `}
    >
      <Text $isLoading={!!isLoading}>{children}</Text>
      {isLoading && <ButtonLoader />}
    </ButtonPrimaryStyled>
  );
}

const ButtonPrimaryStyled = tw(ButtonBase)`
  text-white
  bg-primary
  hover:bg-primaryLight
  disabled:text-gray-700
`;

const Text = tw.span<{ $isLoading: boolean }>`
  ${({ $isLoading }: { $isLoading: boolean }) =>
    $isLoading ? 'opacity-0' : ''}
`;
