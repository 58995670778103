import React from "react";
import tw from "tailwind-styled-components";
import { Button, HeaderSection, Image } from "../../../components";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { MobileAppBannerType } from "../../../types";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";

interface MobileAppBannerProps {
  className?: string;
  data: MobileAppBannerType;
  variables?: PageVariables;
}

export function MobileAppBanner(props: MobileAppBannerProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main style={{ backgroundColor: data.backgroundColor }}>
      <AppCard>
        <LeftCols>
          <HeaderSection
            title={textCompiler(data.title, variables)}
            text={textCompiler(
              isMobile ? data.mobileText : data.text,
              variables
            )}
          />
          <ButtonContainer>
            <BrowserViewStyled>
              <QrCodeContainer>
                <Image src="/qrcode.png" alt="qrcode" />
              </QrCodeContainer>
            </BrowserViewStyled>
            <MobileView>
              <ButtonStyled
                href={data.url}
                color="black"
                target="_blank"
                rel="noreferrer"
              >
                {data.button}
              </ButtonStyled>
            </MobileView>
          </ButtonContainer>
        </LeftCols>
        <ImageContainer>
          <ImageStyled
            src={getStrapiMediaUrl(data?.logo?.data?.attributes)}
            alt={"logo"}
          />
        </ImageContainer>
      </AppCard>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mx-0
  lg:my-16
  lm:px-0 
  font-sans
`;

const AppCard = tw.div`
  rounded-lg
  py-8
  my-16
  flex
  items-center
  justify-between
  flex-col-reverse
  lg:flex-row
`;

const LeftCols = tw.div`
  p-10
`;

const ImageContainer = tw.div`
  w-4/5
  grid
  justify-items-end
`;

const ImageStyled = tw(Image)`
`;

const ButtonContainer = tw.div`
  flex
  flex-col
  lg:flex-row
`;

const ButtonStyled = tw(Button)`
  mt-4
  lg:mt-0
`;

const QrCodeContainer = tw.div`
h-32
w-32
`;

const BrowserViewStyled = tw(BrowserView)`
grid
justify-center
lg:justify-start
`;
