import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { HeaderTitle, MainHeader, RatingsCard } from "../../../components";
import { RatingsSectionRating, RatingsSectionType } from "../../../types";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";
import { Image } from "../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import SliderDot from "../../../components/Button/SliderDot";

interface TestimonialsSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: RatingsSectionType;
}

export function TestimonialsSection(
  props: TestimonialsSectionProps
): JSX.Element {
  const { data, variables } = props;
  const [index, setIndex] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      origin: 0,
      perView: 3,
      spacing: 20,
    },
    breakpoints: {
      "(max-width: 800px)": {
        slides: {
          origin: 0,
          perView: 2,
          spacing: 10,
        },
      },
      "(max-width: 460px)": {
        slides: {
          origin: 0,
          perView: 1,
          spacing: 10,
        },
      },
    },
    created() {
      setLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <Main>
      <Header>
        <HeaderTitle>{textCompiler(data.title, variables)}</HeaderTitle>
        <Subtitle>{textCompiler(data.subtitle, variables)}</Subtitle>
      </Header>
      <div className="relative mt-24">
        <Backgroundlayer style={{ backgroundColor: data.bgColor }}>
          {" "}
        </Backgroundlayer>
        <div ref={sliderRef} className="keen-slider">
          {data.ratings.map((card, i) => (
            <CarousselElement
              key={`carousel-element-${i}`}
              className={`keen-slider__slide number-slide${i}`}
            >
              <Text>{card.text}</Text>
              <BlockContainer>
                {card?.image?.data && (
                  <ImageContainer>
                    <ImageStyled
                      style={{ borderRadius: "9999px" }} // le rounded-full ne marche pas ?
                      src={getStrapiMediaUrl(card.image.data.attributes)}
                      alt="card image"
                    />
                  </ImageContainer>
                )}
                <Author>{`${card.author} - ${card.type}`}</Author>
              </BlockContainer>
            </CarousselElement>
          ))}
        </div>
      </div>
      <div className="w-full  z-50 mt-22 flex justify-center items-center">
        {loaded && instanceRef.current && (
          <Dots>
            {Array.from(Array(data.ratings.length).keys()).map(
              (current: any, i: number) => {
                const active: boolean = currentSlide === i;
                return (
                  <SliderDot
                    key={i}
                    color={data.bgColor}
                    active={active}
                    onclick={() => {
                      instanceRef.current?.moveToIdx(i);
                    }}
                  />
                );
              }
            )}
          </Dots>
        )}
      </div>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  lg:mt-0
  lg:mx-0
  lg:my-16
  py-20
  px-6
  lg:p-24
  relative
  bg-white
`;

const Backgroundlayer = tw.div`
	absolute 
	top-[50%]
	-translate-x-[50%]
	-translate-y-[50%]
	left-[50%]
	bg-[#0C8261]
	h-[350px]
	w-[75%]
	rounded
`;

const Header = tw.div`
  text-center
  mb-8
  select-none
`;

const Subtitle = tw.p`
  my-2
  text-base
  select-none
`;

const CarousselElement = tw.div`
	bg-white
	p-4
	rounded
	drop-shadow-md
	border
	border-gray
	flex flex-col justify-between
	cursor-grabbing
`;

const RatingsCardsContainer = tw.div`
  grid
  items-start
  justify-center
  w-full
  text-center
  select-none
  min-h-[6rem]
`;

const CarouselContainer = tw.div`
flex flex-row
`;

interface CarouselElementProps {
  $displayed: boolean;
}

const CarouselElement = tw.div<CarouselElementProps>`
${(props: CarouselElementProps) => (props.$displayed ? "block" : "hidden")}
`;

const Dots = tw.div`
	flex 
	items-center
	gap-2
`;

const Text = tw.p`
  text-sm
  leading-6
  font-semibold
  mb-6
  text-center
`;

const Author = tw.p`
  text-sm
  text-gray-600
  col-span-4
  py-4
`;

const AuthorContainer = tw.div`

`;

const ImageContainer = tw.div`
  max-w-[40px]
  min-w-[40px]
  py-2
  mr-2
`;

const ImageStyled = tw(Image)`
  rounded-full
`;

const BlockContainer = tw.div`
  flex 
  gap-2
  w-full
`;
