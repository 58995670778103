import React from "react";
import tw from "tailwind-styled-components";

interface SliderDotsProps extends React.LinkHTMLAttributes<HTMLLinkElement> {
  active: boolean;
  color: string;
  onclick: Function;
}

export default function SliderDot(props: SliderDotsProps) {
  const { active, color, onclick } = props;

  return (
    <Dot
      style={{ backgroundColor: active ? color : "#E5E7EB" }}
      onClick={() => onclick()}
      active={active}
    ></Dot>
  );
}

const Dot = tw.div<{ active: boolean }>`
	h-4
	w-4 
	cursor-pointer
	rounded-full
	transition-all
   ${(p) => (p.active ? `w-6` : "")}
`;
