import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { FoldableSectionType, FoldableSectionTypeFold } from '../../../types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { markdownToHtml } from '../../../services/markdown';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import tw from 'tailwind-styled-components';

export interface FoldableSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: FoldableSectionType;
}

export function FoldableSection(props: FoldableSectionProps): JSX.Element {
  const { variables, data, isFullScreen } = props;
  const router = useRouter();

  function getAnchor(): string {
    const routePath = router?.asPath;
    const anchor = routePath?.split('#')[1];
    if (!anchor) {
      return '__NO_ANCHOR__';
    }
    return anchor;
  }

  return (
    <Main $isFullScreen={isFullScreen}>
      <h2 className='font-bold text-smallTitle'>
        {textCompiler(data.title, variables)}
      </h2>
      <dl className='mt-4 space-y-6'>
        {data.folds.map((f: FoldableSectionTypeFold, i) => (
          <div key={i} className='divide-y divide-gray-200'>
            <a href={getAnchor() === f.anchor ? '' : `#${f.anchor}`}>
              <Disclosure
                as='div'
                className='pt-6'
                id={f.anchor}
                defaultOpen={getAnchor() === f.anchor}
              >
                {({ open }) => (
                  <>
                    <dt className='text-base'>
                      <Disclosure.Button className='flex items-start justify-between w-full text-left'>
                        <span
                          className={classNames(
                            'font-medium',
                            open ? 'text-blue' : 'text-black'
                          )}
                        >
                          {textCompiler(f.title, variables)}
                        </span>
                        <span className='flex items-center ml-6 h-7'>
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform',
                              open ? 'text-blue' : 'text-black'
                            )}
                            aria-hidden='true'
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      enter='transition duration-200 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-200 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Disclosure.Panel as='dd' className='pr-12 mt-2'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(f.content),
                          }}
                          className='prose break-words'
                        ></div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </a>
          </div>
        ))}
      </dl>
    </Main>
  );
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
px-4 py-8
${(props: { $isFullScreen?: boolean }) => props.$isFullScreen && 'lg:px-60'}
`;
