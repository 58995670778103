import React, { SelectHTMLAttributes } from 'react';
import tw from 'tailwind-styled-components';

export interface SelectOption extends SelectHTMLAttributes<HTMLSelectElement> {
  value: string;
  label: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[];
  placeholder: string;
}

export function Select(props: SelectProps): JSX.Element {
  const { placeholder, options } = props;
  return (
    <SelectContainer>
      <SelectStyled {...props}>
        {options.map((opt) => {
          return (
            <option defaultValue={0} key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </SelectStyled>
    </SelectContainer>
  );
}

const SelectContainer = tw.div`
    flex
    justify-center
    mb-3
    w-full
`;

const SelectStyled = tw.select`
    form-select
    form-select-lg
    mb-8
    block
    w-full
    p-2
    font-normal
    text-gray-700
    bg-white bg-clip-padding bg-no-repeat
    transition
    ease-in-out
    focus:text-gray-700
    focus:bg-white
    focus:outline-none
    border-r-8
    border-white
`;
