/* eslint-disable indent */
import tw from "tailwind-styled-components";
import { HeaderSection, Image, RealButton } from "../../../components";
import { PageVariables } from "../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { textCompiler } from "../../../services/textCompiler";
import { LogosSectionType, StrapiMedia } from "../../../types";

interface LogosSectionProps {
	variables?: PageVariables;
	isFullScreen?: boolean;
	data: LogosSectionType;
	additionalCta?: boolean;
}

export function LogosSection(props: LogosSectionProps): JSX.Element {
	const { data, variables, isFullScreen, additionalCta = false } = props;

	return (
		<Main $isFullScreen={isFullScreen}>
			<HeaderSection
				title={textCompiler(data.title, variables)}
				text={textCompiler(data.text, variables)}
			/>
			<LogosContainer>
				{data?.logos?.data?.map((logo: StrapiMedia) => (
					<LogoStyled
						key={logo.id}
						src={getStrapiMediaUrl(logo.attributes)}
						alt={logo.attributes.name}
					/>
				))}
			</LogosContainer>
			{additionalCta && (
				<div className='flex justify-center w-full'>
					<RealButton
						onClick={() =>
							window.open(
								"https://share.hsforms.com/1I-WitY_2TCWH7FWzvvfbaQ3hqwd",
								"_blank"
							)
						}
						color='green'>
						Je souhaite être rappelé
					</RealButton>
				</div>
			)}
		</Main>
	);
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lg:px-0 
    ${(props: { $isFullScreen?: boolean }) =>
			props.$isFullScreen && "lg:px-60 lg:my-24"}
  text-center	
`;

const LogosContainer = tw.div`
  flex
  flex-col
  lg:flex-row
  items-center
  mb-10 
  w-full
  gap-16
  justify-center
`;

const LogoStyled = tw(Image)`
my-4
w-2/5
max-h-72
max-w-72
lg:w-full
`;
