import React from 'react';
import tw from 'tailwind-styled-components';
import { HeaderSection } from '../../../components';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import { TwoColorColumnsSectionType } from '../../../types';

interface TwoColorColumnsSectionProps {
  className?: string;
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: TwoColorColumnsSectionType;
}

export function TwoColorColumnsSection(
  props: TwoColorColumnsSectionProps
): JSX.Element {
  const { className, data, variables } = props;

  return (
    <Main className={className}>
      <HeaderContainer>
        <HeaderSection
          title={textCompiler(data.title, variables)}
          text={textCompiler(data.text, variables)}
        />
      </HeaderContainer>
      <TwoColumns>
        <Col1>
          <ColorBlockBlue>
            {textCompiler(data.blueColumn.title, variables)}
          </ColorBlockBlue>
          <TwoColumns>
            <Col1>
              <Card>
                <CardTitle>
                  {textCompiler(data.blueColumn.leftCard.title, variables)}
                </CardTitle>
                <CardText>
                  {textCompiler(data.blueColumn.rightCard.text, variables)}
                </CardText>
              </Card>
            </Col1>
            <Col1>
              <Card>
                <CardTitle>
                  {textCompiler(data.blueColumn.leftCard.title, variables)}
                </CardTitle>
                <CardText>
                  {textCompiler(data.blueColumn.rightCard.text, variables)}
                </CardText>
              </Card>
            </Col1>
          </TwoColumns>
        </Col1>
        <Col1>
          <ColorBlockGreen>
            {textCompiler(data.greenColumn.title, variables)}
          </ColorBlockGreen>
          <TwoColumns>
            <Col1>
              <Card>
                <CardTitle>
                  {textCompiler(data.greenColumn.leftCard.title, variables)}
                </CardTitle>
                <CardText>
                  {textCompiler(data.greenColumn.rightCard.text, variables)}
                </CardText>
              </Card>
            </Col1>
            <Col1>
              <Card>
                <CardTitle>
                  {textCompiler(data.greenColumn.leftCard.title, variables)}
                </CardTitle>
                <CardText>
                  {textCompiler(data.greenColumn.rightCard.text, variables)}
                </CardText>
              </Card>
            </Col1>
          </TwoColumns>
        </Col1>
      </TwoColumns>
    </Main>
  );
}

const Main = tw.div`
py-20
px-4
lg:px-32
  grid
  grid-cols-1
bg-[#F2F2F2]
`;

const HeaderContainer = tw.div`
col-span-1
text-center
`;

const TwoColumns = tw.div`
grid grid-cols-1 lg:grid-cols-2 gap-4 col-span-1
`;

const Col1 = tw.div`
col-span-1
`;

const ColorBlockBlue = tw.div`
font-bold
text-white
bg-gradient-to-r from-[#3459EB] to-[#1233B3]
p-2
text-center
mb-4
`;

const ColorBlockGreen = tw.div`
font-bold
text-white
bg-gradient-to-r from-[#0D8261] to-[#4AA47C]
p-2
text-center
mb-4
`;

const Card = tw.div`
p-5
bg-white
border border-gray-300
`;

const CardTitle = tw.p`
font-semibold
mb-2
`;

const CardText = tw.p`

`;
