import React from "react";
import tw from "tailwind-styled-components";
import { convertPxToRem } from "../../services/convertTo";

type TitleLevel = "h1" | "h2" | "h3";

interface HeaderSectionProps {
  title: string;
  text?: any;
  titleSize?: number;
  textSize?: number;
  level?: TitleLevel;
}

export function HeaderSection(props: HeaderSectionProps): JSX.Element {
  const { title, text, titleSize, textSize, level } = props;

  return (
    <MainHeader>
      <HeaderTitle
        $as={level || "h2"}
        className="HeaderTitle"
        style={{
          fontSize: titleSize && `${convertPxToRem(titleSize)}rem`,
        }}
      >
        {title}
      </HeaderTitle>
      <HeaderText
        style={{
          fontSize: textSize && `${convertPxToRem(textSize)}rem`,
        }}
      >
        {text}
      </HeaderText>
    </MainHeader>
  );
}

export const MainHeader = tw.div`
mb-6
`;

export const HeaderTitle = tw.h1`
  text-lg  
  lg:text-xl 
  font-bold
`;

const HeaderText = tw.p`
  text-base
  my-4 
`;
