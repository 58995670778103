import router from 'next/router';
import tw from 'tailwind-styled-components';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { ArticleCategory } from '../../../types';

export function CategoryBlock(props: {
  category: ArticleCategory;
}): JSX.Element {
  const { category } = props;
  return (
    <CategoryContainer onClick={() => router.push(`/blog/${category.slug}`)}>
      <CategoryImage
        src={getStrapiMediaUrl(category.coverImage.data.attributes)}
      />
      <CategoryTitle>{category.name}</CategoryTitle>
    </CategoryContainer>
  );
}

const CategoryContainer = tw.div`
  col-span-1
  flex
  flex-col
  items-center
  rounded
  p-4
  cursor-pointer
  hover:scale-105
  transition
  bg-white
`;

const CategoryImage = tw.img`
  h-24
  min-h-24
  w-24
  min-w-24
  rounded-full
  mb-6
`;

const CategoryTitle = tw.p`
  font-sans
  font-semibold
  text-center
`;
