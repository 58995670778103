import tw from 'tailwind-styled-components';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export function Input(props: InputProps): JSX.Element {
  const {} = props;

  return <Main {...props} />;
}

const Main = tw.input`
  border-2 
  border-mediumGrey
  p-3
`;
