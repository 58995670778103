/* eslint-disable indent */
import React, { ReactNode } from 'react';
import tw from 'tailwind-styled-components';
import { IFrameBooker } from '../IFrame';

interface ContentLayoutProps {
  children: ReactNode;
  hasBooker: boolean;
  reassurances?: string[];
}

export function ContentLayout(props: ContentLayoutProps): JSX.Element {
  const { children, hasBooker, reassurances } = props;

  return (
    <Main>
      <LeftCol $hasBooker={hasBooker}>
        <IFrameBooker hasBooker={hasBooker} reassurances={reassurances} />
      </LeftCol>
      <RightCol $hasBooker={hasBooker}>{children}</RightCol>
      {hasBooker && <LeftCol $hasBooker={hasBooker} />}
    </Main>
  );
}

const Main = tw.div`
  grid
  grid-cols-12
`;

const LeftCol = tw.div`
  col-span-12
  justify-self-center
  w-full
  max-w-[550px]
  mb-4
  lg:mb-24
  xl:mb-0
${(props: { $hasBooker: boolean }) =>
  props.$hasBooker ? 'lg:col-span-4' : 'lg:hidden mb-0'}
`;

const RightCol = tw.div`
  col-span-12
  
  ${(props: { $hasBooker: boolean }) =>
    props.$hasBooker ? 'lg:col-span-8 lg:w-11/12' : 'lg:col-span-12'}
  
`;
