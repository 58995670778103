export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function replaceAll(
  str: string,
  searchValue: string,
  replaceValue: string
): string {
  return str.replace(new RegExp(searchValue, 'g'), replaceValue);
}
