import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from "next/image";
import React from "react";
import styled from "styled-components";
import tw from "tailwind-styled-components";

export interface ImageProps extends NextImageProps {
  className?: string;
  src: string;
  priority?: boolean;
}

function loader({ src, width, quality }: ImageLoaderProps): string {
  return `${src}?w=${width}&q=${quality || 100}`;
}

export function Image(props: ImageProps): JSX.Element {
  const { className, priority, ...imageProps } = props;
  return (
    <ImageContainer className={className}>
      <NextImage
        loader={loader}
        width={1000}
        height={1000}
        priority={priority}
        {...imageProps}
      />
    </ImageContainer>
  );
}

const ImageContainer = tw.div`
w-full
h-full
relative
z-0
`;

const ResponsiveImage = styled(NextImage)<{ src?: string }>`
  background-size: cover;
  object-fit: contain;
  z-index: 0;
`;
