import Link from "next/link";
import tw from "tailwind-styled-components";

interface BlogBreadcrumbsProps {
  className?: string;
  steps: BlogBreadcrumbsStep[];
}

interface BlogBreadcrumbsStep {
  title: string;
  link?: string;
}

export function BlogBreadcrumbs(props: BlogBreadcrumbsProps): JSX.Element {
  const { steps, className } = props;

  return (
    <Main className={className}>
      {steps.map((s: BlogBreadcrumbsStep, i: number) => (
        <span key={`blog-bc-link-${i}`}>
          <Link href={s.link ?? "/blog"}>
            <Breadcrumb $isLast={i === steps.length - 1}>{s.title}</Breadcrumb>
          </Link>
          {i !== steps.length - 1 && <Separator>/</Separator>}
        </span>
      ))}
    </Main>
  );
}

const Main = tw.div`
  flex flex-row items-center
`;

const Breadcrumb = tw.span<{ $isLast: boolean }>`
  text-gray-400
  font-sm
  ${(props: { $isLast: boolean }) => props.$isLast && "text-black"}
  cursor-pointer
  hover:text-orange-500
`;

const Separator = tw.span`
  text-gray-400
  font-sm
  mx-4
`;
