/* eslint-disable indent */
import NextImage, { ImageLoaderProps } from 'next/image';
import React, { ReactNode } from 'react';
import tw from 'tailwind-styled-components';

interface ButtonProps extends React.LinkHTMLAttributes<HTMLLinkElement> {
  children?: ReactNode;
  icon?: string;
  color: string;
  styled?: string;
  rightIcon?: string;
  target?: string;
  rel?: string;
  className?: string;
}

function loader({ src, width, quality }: ImageLoaderProps): string {
  return `${src}?w=${width}&q=${quality || 100}`;
}

export function Button(props: ButtonProps): JSX.Element {
  const {
    children,
    icon,
    color,
    href,
    styled,
    rightIcon,
    target,
    rel,
    className,
  } = props;
  return (
    <a
      href={href}
      target={target}
      rel={rel}
      className={`py-3 px-6 mr-6 text-sm text-white flex items-center justify-center bg-gradient-to-r hover:cursor-pointer ${
        color == 'green' ? 'from-bookerButtonGreen1' : 'from-blue'
      } ${
        color == 'green' ? 'to-bookerButtonGreen2' : 'to-blueLight'
      } ${styled} ${className} `}
    >
      {icon ? (
        <LeftIconContainer>
          <NextImage
            width={30}
            height={30}
            src={`/${icon}`}
            alt={icon}
            loader={loader}
          />
        </LeftIconContainer>
      ) : (
        <></>
      )}
      {children}
      {rightIcon ? (
        <RightIconContainer>
          <NextImage
            width={30}
            height={30}
            src={`/${rightIcon}`}
            alt={rightIcon}
            loader={loader}
          />
        </RightIconContainer>
      ) : (
        <></>
      )}
    </a>
  );
}

const LeftIconContainer = tw.div`
  flex
  mr-2
`;

const RightIconContainer = tw.div`
  flex
  ml-2
`;
