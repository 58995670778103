import React from 'react';
import tw from 'tailwind-styled-components';
import { HeaderTitle, RatingsCard } from '../../../components';
import { RatingsSectionType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';

interface RatingsSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: RatingsSectionType;
}

export function RatingsSection(props: RatingsSectionProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <Header>
        <HeaderTitle>{textCompiler(data.title, variables)}</HeaderTitle>
        <Subtitle>{textCompiler(data.subtitle, variables)}</Subtitle>
      </Header>
      <RatingsCardsContainer>
        {data.ratings.map((card, i) => (
          <RatingsCard key={i} card={card} />
        ))}
      </RatingsCardsContainer>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  lg:mt-0
  lg:mx-0
  lg:my-16
  bg-black
  text-white
  py-20
  pl-6
  lg:p-24
`;

const Header = tw.div`
  text-center
  mb-10
`;

const Subtitle = tw.p`
  my-2
  text-base
`;

const RatingsCardsContainer = tw.div`
  lg:grid
  lg:grid-cols-3
  lg:gap-6
  flex flex-row overflow-scroll
  items-start
  scrollbar-hide
`;
