/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable indent */
import React, { useState } from 'react';
import tw from 'tailwind-styled-components';
import {
  ButtonPrimary,
  HeaderSection,
  Image,
  Input,
} from '../../../components';
import { PageVariables } from '../../../services/pageVariables';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { textCompiler } from '../../../services/textCompiler';
import { NewsLetterBannerType } from '../../../types';

interface NewsLetterBannerProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: NewsLetterBannerType;
}

export function NewsLetterBanner(props: NewsLetterBannerProps): JSX.Element {
  const { data, variables, isFullScreen } = props;

  const [email, setEmail] = useState('');
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendToMailChimp = async () => {
    setIsLoading(true);
    const response = await fetch(`/bob/mailchimp/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setHasSubscribed(!!(response.status === 200));
    setHasError(!!(response.status === 500));
    setIsLoading(false);
  };

  return (
    <Main
      style={{ backgroundColor: data.backgroundColor }}
      $isFullScreen={isFullScreen}
    >
      <LeftCol>
        {data.image.data && (
          <Image
            src={getStrapiMediaUrl(data.image.data.attributes)}
            alt={data.image.data.attributes.name}
          />
        )}
      </LeftCol>
      <RightCol>
        <HeaderSection
          title={textCompiler(data.title, variables)}
          text={textCompiler(data.text, variables)}
        />
        <InputContainer>
          <InputStyled
            placeholder={'Entrez votre e-mail'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <ButtonStyled
            isLoading={isLoading}
            onClick={sendToMailChimp}
            color='green'
          >
            {"Je m'inscris"}
          </ButtonStyled>
        </InputContainer>
        {hasSubscribed && (
          <GreenText>
            {
              'Merci pour votre inscription ! Surveillez votre boite mail pour recevoir nos offres !'
            }
          </GreenText>
        )}
        {hasError && <RedText>{"Erreur lors de l'inscription"}</RedText>}
      </RightCol>
    </Main>
  );
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
  my-16
  lm:px-0 
  lg:p-10
  px-4
  py-10
  grid
  grid-cols-3
  items-center
    ${(props: { $isFullScreen?: boolean }) => props.$isFullScreen && 'lg:px-60'}
`;

const LeftCol = tw.div`
  col-span-3
  lg:col-span-1
  grid
  mb-4
  lg:mb-0
`;

const RightCol = tw.div`
  lg:ml-4
  col-span-3
  lg:col-span-2
`;

const InputContainer = tw.div`
  lg:flex
  w-full
`;
const InputStyled = tw(Input)`
  flex-1
  mr-2
  w-full
  mb-2
  lg:mb-0
`;

const ButtonStyled = tw(ButtonPrimary)`
mr-0
`;

const GreenText = tw.div`
  text-green
`;

const RedText = tw.div`
  text-red-600
`;
