import { MutableRefObject, useEffect, useRef, useState } from "react";
import tw from "tailwind-styled-components";

interface DropdownProps {
  className?: string;
  options: { label: string; url: string }[];
  children: JSX.Element;
  optionsWidth?: string;
}

export function Dropdown(props: DropdownProps): JSX.Element {
  const { children, className, options, optionsWidth } = props;

  const [isOpen, setIsOpen] = useState(false);

  const ref: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function onClickOutside() {
      setIsOpen(false);
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (!event?.target) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <TriggerContainer
        className={className}
        onClick={() => setIsOpen(!isOpen)}
        ref={ref}
      >
        {children}
        <OptionsContainer
          $isOpen={isOpen}
          $width={optionsWidth}
          style={{ zIndex: 1000 }}
        >
          {options.map((option, index) => (
            <Option key={index} href={option.url}>
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      </TriggerContainer>
    </>
  );
}

const TriggerContainer = tw.div`
relative`;

const OptionsContainer = tw.div<{ $isOpen: boolean; $width?: string }>`
${(props: { $isOpen: boolean }) => (props.$isOpen ? "block" : "hidden")}
origin-top-right
absolute
mt-10
rounded-md
shadow-lg
bg-white
ring-black
ring-1
ring-opacity-5
focus:outline-none
right-0
${(props: { $width?: string }) => `w-${props.$width ?? "44"}`}
`;

const Option = tw.a`
text-gray-700
block
px-4
py-2
text-sm
hover:bg-gray-100
`;
