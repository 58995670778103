import removeMarkdown from 'markdown-to-text';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { RealButton } from '../../../components/Button';
import { formatFrenchFullDate } from '../../../services/blog';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { capitalize } from '../../../services/utils';
import { PageArticleType } from '../../../types';

export function SwiperArticleCard(props: {
  className?: string;
  hidePreview?: boolean;
  article: PageArticleType;
  onClick: () => void;
}): JSX.Element {
  const { article, onClick, className, hidePreview } = props;
  return (
    <SwiperArticleCardContainer
      $bgUrl={getStrapiMediaUrl(article.coverImage.data.attributes)}
      className={className}
    >
      <Relative>
        <WhiteBlock onClick={onClick}>
          <SwiperArticleCardTitle>
            {article?.title ?? ''}
          </SwiperArticleCardTitle>
          <SwiperArticleDate>
            {article?.date
              ? capitalize(formatFrenchFullDate(new Date(article.date)))
              : ''}
            {' · '}
            {article.author ?? ''}
          </SwiperArticleDate>
          {!hidePreview && (
            <SwiperArticlePreview
              dangerouslySetInnerHTML={{
                __html:
                  removeMarkdown(article?.content ?? '').slice(0, 320) + '...',
              }}
            ></SwiperArticlePreview>
          )}
        </WhiteBlock>
        <div className='block w-48 mt-6'>
          <RealButton className='!mr-0' color='green' onClick={onClick}>
            {"Lire l'article"}
          </RealButton>
        </div>
      </Relative>
    </SwiperArticleCardContainer>
  );
}

const SwiperArticleCardContainerBase = tw.div`
  col-span-1
  flex
  flex-col
  w-full
  h-full
  justify-center
  rounded
  overflow-hidden
  shadow-md
  bg-white
  text-center
`;

const SwiperArticleCardContainer = styled(SwiperArticleCardContainerBase)<{
  $bgUrl: string;
}>`
  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${(props: { $bgUrl: string }) => {
      return `background-image: url(${props.$bgUrl});`;
    }}
    background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(0.4);
  }
`;

const Relative = tw.div`
  relative
  flex
  flex-col
  items-center
  lg:px-8
  2xl:px-48
  px-4
  lg:py-16
  py-4
`;

const WhiteBlock = tw.div`
  relative
  flex
  flex-col
  items-center
  lg:px-8
  px-4
  lg:py-8
  py-4
  bg-white  
  hover:shadow-xl
  hover:scale-105
  transition
  cursor-pointer
`;

const SwiperArticleCardTitle = tw.p`
  text-title
  mb-4
  mt-4
`;

const SwiperArticleDate = tw.p`
  text-orange-400
  mb-8
`;

const BlueLinks = styled.p`
  a {
    color: #0000ee;
  }
`;

const SwiperArticlePreview = tw(BlueLinks)`
  text-sm
  text-gray-500
  pb-4
`;
