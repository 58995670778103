/* eslint-disable indent */
import NextImage, { ImageLoaderProps } from "next/image";
import React, { ReactNode } from "react";
import tw from "tailwind-styled-components";

interface RealButtonProps extends React.LinkHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  icon?: string;
  color: string;
  styled?: string;
  rightIcon?: string;
  className?: string;
}

function loader({ src, width, quality }: ImageLoaderProps): string {
  return `${src}?w=${width}&q=${quality || 100}`;
}

export function RealButton(props: RealButtonProps): JSX.Element {
  const { children, icon, color, styled, rightIcon, className, onClick } =
    props;
  return (
    <div
      onClick={onClick}
      className={`py-3 px-6 text-sm text-white flex items-center justify-center bg-gradient-to-r hover:opacity-80 hover:cursor-pointer ${
        color == "green" ? "from-green" : "from-blue"
      } ${
        color == "green" ? "to-greenLight" : "to-blueLight"
      } ${styled} ${className} `}
    >
      {icon ? (
        <LeftIconContainer>
          <NextImage
            width={30}
            height={30}
            src={`/${icon}`}
            alt={icon}
            loader={loader}
          />
        </LeftIconContainer>
      ) : (
        <></>
      )}
      {children}
      {rightIcon ? (
        <RightIconContainer>
          <NextImage
            width={30}
            height={30}
            src={`/${rightIcon}`}
            alt={rightIcon}
            loader={loader}
          />
        </RightIconContainer>
      ) : (
        <></>
      )}
    </div>
  );
}

const LeftIconContainer = tw.div`
  flex
  mr-2
`;

const RightIconContainer = tw.div`
  flex
  ml-2
`;
