import tw from 'tailwind-styled-components';
import { PageArticleType } from '../../../types';
import { BlogSearchBar } from './BlogSearchBar';

interface BlogHeaderProps {
  className?: string;
  title: string;
  allArticles: PageArticleType[];
}

export function BlogHeader(props: BlogHeaderProps): JSX.Element {
  const { title, className, allArticles } = props;

  return (
    <Header className={className}>
      <ColSpan3 />
      <Title>{title}</Title>
      <ColSpan3>
        <BlogSearchBar articles={allArticles} />
      </ColSpan3>
    </Header>
  );
}

const Header = tw.div`
  w-full
  px-8
  lg:px-18
  grid
  lg:grid-cols-12
  grid-cols-1
  gap-8
  mt-20
  mb-12
`;

const ColSpan3 = tw.div`
  lg:col-span-3
  col-span-1
  justify-center
  lg:justify-end
  flex
`;

const Title = tw.h1`
  lg:col-span-6
  col-span-1
  text-[46px]
  font-bold
  font-sans
  mb-2
  mr-4
  lg:mb-0
  text-center
  w-full
`;
