import React from "react";
import { PageVariables } from "../../../services/pageVariables";
import tw from "tailwind-styled-components";
import { Card, HeaderSection } from "../../../components";
import { textCompiler } from "../../../services/textCompiler";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { Image } from "../../../components";
import { CardsSectionCard } from "../../../types";
import { DatasSectionType } from "../../../types/strapi/DatasSectionType";

interface CardsSectionProps {
	variables?: PageVariables;
	isFullScreen?: boolean;
	data: DatasSectionType;
	additionalCta?: boolean;
}

export default function DatasSection(props: CardsSectionProps) {
	const { data, variables, isFullScreen } = props;

	return (
		<Main $isFullScreen={isFullScreen}>
			<HeaderSection
				title={textCompiler(data.title, variables)}
				titleSize={data.titleSize}
				text={textCompiler(data.subtitle, variables)}
			/>
			<div className='flex mt-10 flex-col lg:flex-row items-center gap-8'>
				<DatasContainer>
					{data.data.map((card: CardsSectionCard) => (
						<Card key={card.id} card={card} variables={variables} />
					))}
				</DatasContainer>
				<ImageContainer>
					<ImageStyled
						src={getStrapiMediaUrl(data.image.data.attributes)}
						alt={data.image.data.attributes.name}
					/>
				</ImageContainer>
			</div>
		</Main>
	);
}

const Main = tw.div`
   mt-lg
   mx-sm
   lg:mt-0
   lg:mx-0
   lg:my-32
   lm:px-0 
   ${(props: { $isFullScreen?: boolean }) =>
			props.$isFullScreen && "lg:px-60 text-center lg:my-24"}
`;

const DatasContainer = tw.div`
   grid gap-6 grid-cols-2
   lg:w-1/2
`;

const ImageContainer = tw.div`
   flex
   justify-start
   items-start
   lg:justify-center
   lg:w-1/2
   w-full
`;

const ImageStyled = tw(Image)`
w-full
`;
