/* eslint-disable indent */
import React from "react";
import tw from "tailwind-styled-components";
import { Button, Image } from "../../../components";
import { RSETitleSectionType, TitlesSectionType } from "../../../types";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";
import { getStrapiMediaUrl } from "../../../services/strapiApi";

interface RSETitleSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: RSETitleSectionType;
}

export function RSETitleSection(props: RSETitleSectionProps): JSX.Element {
  const { data, variables } = props;
  const title = textCompiler(data.title, variables);
  const text = textCompiler(data.text, variables);
  const topTitle = textCompiler(data.topTitle, variables);
  const hasImage = !!data.image && !!data.image.data;
  const hasBanner = !!data.banner;
  return (
    <Main>
      <HeroContainer
        style={{ backgroundColor: data.backgroundColor }}
        $hasImage={hasImage}
      >
        <ColText $hasImage={hasImage}>
          <Text>{topTitle}</Text>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {data.button && (
            <ButtonContainer>
              <ButtonStyled href={data.button.destination} color="green">
                {data.button.label}
              </ButtonStyled>
            </ButtonContainer>
          )}
        </ColText>
        {data.image && data.image.data && (
          <Col1>
            <ImageContainer>
              <Image
                src={getStrapiMediaUrl(data.image.data.attributes)}
                alt="rse"
              />
            </ImageContainer>
          </Col1>
        )}
      </HeroContainer>
      {hasBanner && (
        <Banner>
          {data.banner.leftContent && (
            <CardContainer>
              <CardTitle>{data.banner.leftContent.title}</CardTitle>
              <CardText>{data.banner.leftContent.text}</CardText>
            </CardContainer>
          )}
          {data.banner.middleContent && (
            <CardContainer>
              <CardTitle>{data.banner.middleContent.title}</CardTitle>
              <CardText>{data.banner.middleContent.text}</CardText>
            </CardContainer>
          )}
          {data.banner.rightContent && (
            <CardContainer>
              <CardTitle>{data.banner.rightContent.title}</CardTitle>
              <CardText>{data.banner.rightContent.text}</CardText>
            </CardContainer>
          )}
        </Banner>
      )}
    </Main>
  );
}

const Main = tw.div<{ $hasImage?: boolean }>`
  flex
  flex-col
`;

const HeroContainer = tw.div`
  grid 
  grid-cols-1
${(props: { $hasImage?: boolean }) =>
  props.$hasImage ? "lg:grid-cols-8" : "lg:grid-cols-4"}
`;

const ColText = tw.div<{ $hasImage?: boolean }>`
col-span-1
lg:col-span-4
text-black
grid
content-center
    ${(props: { $hasImage?: boolean }) => !props.$hasImage && "text-center"}
    ${(props: { $hasImage?: boolean }) =>
      props.$hasImage ? "lg:pl-40" : "lg:px-40"}

lg:py-10
order-2
lg:order-1
`;

const Col1 = tw.div`
lg:py-10
col-span-1
lg:col-span-4
order-2
lg:order-2
`;

const Title = tw.p`
font-bold
text-[3.5rem]
leading-[4rem]
mb-8
break-words
p-4
lg:px-20
`;

const Text = tw.p`
text-base
p-4
lg:px-20
`;

const ImageContainer = tw.div`
p-0
lg:p-10
`;

const ButtonContainer = tw.div`
px-12
py-6
lg:py-12
lg:px-20
`;

const ButtonStyled = tw(Button)`
max-w-max
mr-0
`;

const Banner = tw.div`
bg-[#0D8261]
flex
flex-col
lg:flex-row
justify-between
py-8
lg:py-0
lg:px-52
`;

const CardContainer = tw.div`
flex
flex-col
lg:py-12
`;

const CardTitle = tw.p`
font-bold
text-[3.5rem]
text-white
break-words
lg:p-4
lg:px-20
text-center`;

const CardText = tw.p`
text-base
text-white
text-center
mb-8
break-words
p-4
lg:px-20`;
