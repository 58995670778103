import router from "next/router";
import React from "react";
import tw from "tailwind-styled-components";
import { Card, HeaderSection, RealButton } from "../../../components";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";
import { CardsSectionCard, CardsSectionType } from "../../../types";
import { CTAType } from "../../../types/strapi/CTAType";

interface CardsSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: CardsSectionType;
  additionalCta?: boolean;
}

const DEFAULT_COLUMNS = 2;

export function CardsSection(props: CardsSectionProps): JSX.Element {
  const { data, variables, isFullScreen, additionalCta } = props;

  //console.log(data);

  return (
    <Main $isFullScreen={isFullScreen}>
      <HeaderSection
        title={textCompiler(data.title, variables)}
        titleSize={data.titleSize}
        text={textCompiler(data.text, variables)}
        textSize={data.textSize}
      />
      <CardsContainer $columns={data.columns ?? DEFAULT_COLUMNS}>
        {data.cards?.map((card: CardsSectionCard) => (
          <Card key={card.id} card={card} variables={variables} />
        ))}
      </CardsContainer>
      {additionalCta && (
        <div className="flex justify-center w-full mt-8">
          {data.cta.map((button: CTAType, i: number) => (
            <RealButton
              key={`CardsSection-RealButton-${i}`}
              onClick={() =>
                window.open(
                  button?.destination,
                  button?.newWindow ? "_blank" : ""
                )
              }
              color={button?.bgColor}
              className="mr-6"
            >
              {button?.label}
            </RealButton>
          ))}
        </div>
      )}
    </Main>
  );
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-32
  lm:px-0 
  ${(props: { $isFullScreen?: boolean }) =>
    props.$isFullScreen && "xl:px-[12%] lg:px-14 text-center lg:my-24"}
`;

const CardsContainer = tw.div`
  grid
  ${(props: { $columns: number }) => `lg:grid-cols-${props.$columns}`}
  grid-cols-1
  md:grid-cols-2
  justify-items-center
  items-start
  gap-4
`;
