import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { textCompiler } from '../../../services/textCompiler';

interface SeoHeadProps {
  title?: string;
  description?: string;
  variables?: object;
}

export function SeoHead(props: SeoHeadProps): JSX.Element {
  const { asPath } = useRouter();

  const canonicalUrl = asPath.split('?')[0];

  const title = props.title
    ? textCompiler(props.title, props.variables)
    : process.env.NEXT_PUBLIC_DEFAULT_META_TITLE;
  const description = props.description
    ? textCompiler(props.description, props.variables)
    : process.env.NEXT_PUBLIC_DEFAULT_META_DESCRIPTION;

  return (
    <div>
      <Head>
        <>
          <title>{title}</title>
          <meta name='description' content={description} />
          <meta property='og:title' content={title} key='title' />
          <meta
            property='url'
            content={`${process.env.NEXT_PUBLIC_APP_URL}${asPath}`}
          />
          <meta property='type' content='website' />
          <meta
            property='image'
            content={`${process.env.NEXT_PUBLIC_APP_URL}/logo-orange.jpg`}
          />
          <meta
            property='og:description'
            content={description}
            key='description'
          />
          <meta
            property='og:url'
            content={`${process.env.NEXT_PUBLIC_APP_URL}${asPath}`}
          />
          <meta property='og:type' content='website' />
          <meta
            property='og:image'
            content={`${process.env.NEXT_PUBLIC_APP_URL}/logo-orange.jpg`}
          />
          <link
            rel='canonical'
            href={`${process.env.NEXT_PUBLIC_APP_URL}${canonicalUrl}`}
          />
        </>
      </Head>
    </div>
  );
}
