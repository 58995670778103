import React, { ReactNode } from "react";
import tw from "tailwind-styled-components";

interface PageLayoutProps {
  children: ReactNode;
  className?: string;
}

export function PageLayout(props: PageLayoutProps): JSX.Element {
  const { children, className } = props;

  return (
    <Main className={className}>
      <ContentContainer>{children}</ContentContainer>
    </Main>
  );
}

const Main = tw.div`
  flex
  flex-col
  font-sans
`;

const ContentContainer = tw.div`
`;
