import router from "next/router";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import tw from "tailwind-styled-components";
import { RealButton } from "../../../components/Button";
import { Swiper } from "../../../components/Swiper";
import { markdownToHtml } from "../../../services/markdown";
import { BlogNews } from "../../../types";

interface NewsSliderProps {
  className?: string;
  news: BlogNews[];
}

export function NewsSlider(props: NewsSliderProps): JSX.Element {
  const { className, news: blogNews } = props;

  return (
    <Main className={className}>
      <Swiper
        className="w-full h-full shadow-md"
        swiperclassname="mb-8 lg:mb-0"
      >
        {blogNews.map((news: BlogNews, i: number) => (
          <SwiperSlide key={i} className={"w-full h-full"}>
            <NewsContainer>
              <NewsTitle>{news.title}</NewsTitle>
              <NewsContent
                dangerouslySetInnerHTML={{
                  __html: markdownToHtml(news.content),
                }}
              />
              {news.ctaTitle && news.ctaLink && (
                <CtaContainer>
                  <RealButton
                    className="ml-0 mr-0"
                    color="green"
                    onClick={() => news.ctaLink && router.push(news.ctaLink)}
                  >
                    {news.ctaTitle}
                  </RealButton>
                </CtaContainer>
              )}
            </NewsContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Main>
  );
}

const Main = tw.div`
  flex
  flex-col
  w-full
  h-full
`;

const NewsContainer = tw.div`
  flex
  flex-col
  w-full
  h-full
  overflow-x-visible
  px-2
  pt-8
  justify-center
  items-center
`;

const NewsTitle = tw.p`
  font-sans
  font-bold
  text-base
  text-center
  select-none
  mb-8
`;

const ImageMaxSize = styled.p`
  img {
    max-height: 330px;
    justify-self: center;
  }
`;

const NewsContent = tw(ImageMaxSize)`
  flex
  font-sans
  select-none
  text-justify
`;

const CtaContainer = tw.div`
  flex
  w-full
  justify-center
  mt-4
  pb-4
`;
