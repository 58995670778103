export function shuffleArray<T>(arr: T[]): T[] {
  return [...arr].sort(() => 0.5 - Math.random());
}

export function removeFromArray<T>(arr: T[], toRemove: T[]): T[] {
  return arr.filter((item) => !toRemove.includes(item));
}

export function generateArrayOfNElements<T>(element: T, times: number): T[] {
  return Array.from({ length: times }).map(() => element);
}
