import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export function formatStrapiDate(date: string): string {
  // Strapi date : YYYY-MM-DD
  // Our date : DD/MM/YYYY
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

export function formatFrenchFullDate(date: Date): string {
  return format(date, 'PPPP', { locale: fr });
}
