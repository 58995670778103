import tw from 'tailwind-styled-components';

export interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export function ButtonBase(props: ButtonBaseProps): JSX.Element {
  const { children } = props;

  return <Button {...props}>{children}</Button>;
}

const Button = tw.button`
  relative
  text-center
  py-3
  px-5
  disabled:bg-gray-200
  disabled:border-none
  border
  transition
  h-fit
  w-fit
`;
