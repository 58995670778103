import React from 'react';
import tw from 'tailwind-styled-components';
import { ContactIFrame } from '../../../components';
import { PageVariables } from '../../../services/pageVariables';
import { ContactUsSectionType } from '../../../types';

interface ContactUsSectionProps {
  className?: string;
  data: ContactUsSectionType;
  variables?: PageVariables;
  isFullScreen?: boolean;
}

export function ContactUsSection(props: ContactUsSectionProps): JSX.Element {
  const { className, data } = props;

  return (
    <Main className={className}>
      <Col>
        <Title>{data.title}</Title>
        <Description>{data.description}</Description>
      </Col>
      <Col>
        <ContactIFrame />
      </Col>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  lg:mt-0
  lg:mx-0
  lg:my-16
  py-20
  px-6
  lg:p-24
  bg-[#F2F2F2]
  grid content-center justify-center
  grd-cols-1
`;

const Col = tw.div`
col-span-1
grid content-center px-4 lg:px-20 justify-center
`;

const Title = tw.p`
text-lg
text-center
mb-4
font-bold
`;

const Description = tw.p`
text-center
`;
