import React from 'react';
import tw from 'tailwind-styled-components';
import { RatingsSectionRating } from '../../types';

interface RatingsCardProps {
  className?: string;
  card: RatingsSectionRating;
}

export function RatingsCard(props: RatingsCardProps): JSX.Element {
  const { card, className } = props;

  return (
    <Main className={className}>
      <Text>{'“ ' + card.text + ' ”'}</Text>
      <Author>{`${card.author} - ${card.type}`}</Author>
    </Main>
  );
}

const Main = tw.div`
lg:col-span-1
p-6
bg-white
text-black
lg:mr-0 mr-6
min-w-fit
min-h-fit
`;

const Text = tw.p`
  text-sm
  leading-6
  font-semibold
  mb-6
`;

const Author = tw.p`
  text-sm
  text-gray-600
`;
