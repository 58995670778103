import React from 'react';
import tw from 'tailwind-styled-components';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { LargeCardsSectionType, LargeCardType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import { Image } from '../../../components';

interface LargeCardsSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: LargeCardsSectionType;
}

export function LargeCardsSection(props: LargeCardsSectionProps): JSX.Element {
  const { data, variables, isFullScreen } = props;

  return (
    <Main $isFullScreen={isFullScreen}>
      <Title>{textCompiler(data.title, variables)}</Title>
      <Text>{textCompiler(data.text, variables)}</Text>
      {data.cards.map((card: LargeCardType) => (
        <Card key={card.id}>
          <ImageContainer>
            <ImageStyled
              src={getStrapiMediaUrl(card?.image?.data?.attributes)}
              alt={card?.image?.data?.attributes?.name}
            />
          </ImageContainer>
          <InfosContainer>
            <CardTopTitle>
              {textCompiler(card.topTitle, variables)}
            </CardTopTitle>
            <CardTitle>{textCompiler(card.title, variables)}</CardTitle>
            <CardSubTitle>
              {textCompiler(card.subTitle, variables)}
            </CardSubTitle>
            <Button href={card.destination}>{'Découvrir'}</Button>
          </InfosContainer>
        </Card>
      ))}
    </Main>
  );
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
  my-32
  ${(props: { $isFullScreen?: boolean }) => props.$isFullScreen && 'lg:px-60'}
`;

const Title = tw.h2`
  text-xl 
  font-bold
  text-center
`;

const Text = tw.p`
  text-center 
  text-sm
  mt-4
  mb-16
`;

const Card = tw.div`
  bg-lightGrey
  min-h-96
  overflow-hidden
  mb-4
  p-8
  last:mb-0
  flex flex-col lg:flex-row
`;

const ImageContainer = tw.div`
  grid
  mb-4
  lg:mb-0
h-80
w-80
max-h-80
max-w-80
min-h-[20rem]
min-w-[20rem]
overflow-hidden
content-center
`;

const ImageStyled = tw(Image)`
object-cover
`;

const InfosContainer = tw.div`
  flex
  flex-col
  p-8
`;

const CardTopTitle = tw.p`
  text-xs 
  font-bold
`;

const CardTitle = tw.h2`
  text-lg 
  font-bold 
  mt-4
`;

const CardSubTitle = tw.p`
  text-sm
  mt-2
`;

const Button = tw.a`
  mt-4
  text-sm
  text-blue
  underline
  underline-offset-4
`;
