import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { PreFooters, PreFooterSectionItem } from "../../../types";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";
import { useRouter } from "next/router";

interface PreFooterSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: PreFooters;
}

interface PreFooterProps {
  title: string;
  items: PreFooterSectionItem[];
  variables?: PageVariables;
  isFullScreen?: boolean;
}

function PreFooter(props: PreFooterProps): JSX.Element {
  const { title, items, variables } = props;

  const router = useRouter();
  const defaultLocale = "fr"; // Langue par défaut

  const [language, setLanguage] = useState(defaultLocale);

  useEffect(() => {
    // Déterminer la langue en fonction de l'URL
    const detectedLanguage = /(^|\/)en(\/|$)/.test(router.asPath)
      ? "en"
      : defaultLocale;

    // Stocker la langue dans le local storage
    localStorage.setItem("language", detectedLanguage);

    // Mettre à jour l'état local pour refléter la langue détectée
    setLanguage(detectedLanguage);
  }, [router.asPath]);

  useEffect(() => {
    // Vérifier s'il existe déjà une langue dans le local storage au chargement
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  return (
    <Row>
      <Title>{textCompiler(title, variables)}</Title>
      <LinksContainer>
        {items.map((item, i) => (
          <Link key={i} href={item.url}>
            {textCompiler(`{type} à ${item.name}`, variables)}
          </Link>
        ))}
      </LinksContainer>
    </Row>
  );
}

export function PreFooterSection(props: PreFooterSectionProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <PreFooter
        title={"Recherches associées à {type} {city}"}
        items={data.proximity.items}
        variables={variables}
      />
      <PreFooter
        title={"Recherches fréquentes à {type} {city}"}
        items={data.bestResults.items}
        variables={variables}
      />
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lm:px-0 
  px-5
  grid grid-cols-2
`;

const Row = tw.div`
  py-8
  px-8
  border-t
  col-span-1
`;

const Title = tw.p`
  text-base
`;

const LinksContainer = tw.div`
  grid
  lg:grid-cols-2
  grid-cols-1
`;

const Link = tw.a`
  text-sm
  mt-4
  text-darkGrey
  hover:underline 
  hover:text-blue
  `;
