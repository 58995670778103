import React from 'react';
import tw from 'tailwind-styled-components';
import { HeaderSection } from '../../../components';
import { TransfertsSectionType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import { markdownToHtml } from '../../../services/markdown';

interface TransfertsSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: TransfertsSectionType;
}

export function TransfertsSection(props: TransfertsSectionProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <HeaderSection
        title={textCompiler(data.title, variables)}
        text={textCompiler(data.text, variables)}
      />
      <CardsContainer>
        {data.cards.map((card) => (
          <Card key={card.id}>
            <CardTitle>
              {card.title && (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(textCompiler(card.title, variables)),
                  }}
                  className='break-words'
                />
              )}
            </CardTitle>
            <CardSubTitle>
              {card.subTitle && (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(
                      textCompiler(card.subTitle, variables)
                    ),
                  }}
                  className='prose break-words'
                />
              )}
            </CardSubTitle>
            <CardText>
              {card.text && (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(textCompiler(card.text, variables)),
                  }}
                  className='prose break-words'
                />
              )}
            </CardText>
          </Card>
        ))}
      </CardsContainer>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lm:px-0 
  px-4
`;

const CardsContainer = tw.div`
  grid
  lg:grid-cols-3
  gap-4
  pb-10
  lg:pb-0
`;

const Card = tw.div`
  mt-2
`;

const CardTitle = tw.h2`
  text-base
  font-bold
`;

const CardSubTitle = tw.h3`
  text-base
  mt-2
`;

const CardText = tw.span`
  mt-2
  text-sm
  text-darkGrey
`;

const Content = tw.span`
w-full min-w-full
`;
