import React from "react";
import tw from "tailwind-styled-components";
import { markdownToHtml } from "../../../services/markdown";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";
import { ContentSectionType, SimplesSectionType } from "../../../types";

interface ContentSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: ContentSectionType;
  hideHeader?: boolean;
}

export function ContentsSection(props: ContentSectionProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main className="ContentsSection">
      <Article>
        <Title>{textCompiler(data.title, variables)}</Title>
        {data && data.content && (
          <Content
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(textCompiler(data.content, variables)),
            }}
            className="prose break-words"
          />
        )}
      </Article>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lm:px-0 
`;

const Article = tw.div`
grid px-24 lg:px-4 w-full
content-center justify-center
`;

const Title = tw.h1`
w-full min-w-full 
text-center
text-xl
my-8
`;

const Content = tw.p`
w-full min-w-full
pb-20
`;
