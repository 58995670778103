import { ContentSectionType } from "./../types/strapi/ContentSectionType";
/* eslint-disable indent */
import qs from "qs";
import axios from "axios";
import {
  ArticleCategory,
  CardsSectionType,
  CityType,
  ContactUsSectionType,
  EcologyBannerType,
  FoldableSectionType,
  FooterSectionType,
  ImageBannerSectionType,
  LargeCardsSectionType,
  LinksSectionType,
  LogosSectionType,
  MapsCardSectionType,
  MobileAppBannerType,
  NavigationBarType,
  NewsLetterBannerType,
  PageAnnuaire,
  PageArticleType,
  PageBaseType,
  PageBlog,
  PageInfoType,
  PageSectionType,
  PlaceType,
  RatingsSectionType,
  RSETitleSectionType,
  SectionsCollectionType,
  SectionsSingleType,
  SectionStrapiTypeEnum,
  SectionsType,
  SectionTypeEnum,
  SimplesSectionType,
  StrapiMediaAttributes,
  StrapiResponse,
  StrapiSingleTypeResponse,
  TitlesSectionType,
  TransfertsSectionType,
  TwoColorColumnsSectionType,
} from "../types";
import { fromCache } from "./cache";
import { DatasSectionType } from "../types/strapi/DatasSectionType";
import { LandingPage } from "../types/strapi/v2/pages";

async function fetchStrapiAPI<T>(path: string): Promise<StrapiResponse<T>> {
  console.log("fetchStrapiAPI", path);
  const requestUrl = `${process.env.NEXT_PUBLIC_STRAPI_URL}/api${path}`;

  const response = await axios.get(requestUrl, {
    headers: {
      Authorization: `bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`,
    },
    timeout: 1000 * 30,
  });
  const data: StrapiResponse<T> = response.data;
  return data;
}

async function fetchStrapiSingleTypeAPI<T>(
  path: string
): Promise<StrapiSingleTypeResponse<T>> {
  console.log("fetchStrapiSingleTypeAPI", path);
  const requestUrl = `${process.env.NEXT_PUBLIC_STRAPI_URL}/api${path}`;
  const response = await axios.get(requestUrl, {
    headers: {
      Authorization: `bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`,
    },
    timeout: 1000 * 30,
  });
  const data: StrapiSingleTypeResponse<T> = response.data;
  return data;
}

function formatEqFilter(name: string, value: string): string {
  const filter = {
    filters: {
      [name]: {
        $eq: value,
      },
    },
  };
  return `${qs.stringify(filter, {
    encodeValuesOnly: true,
  })}`;
}

function formatStrapiResponseData<T>(
  data: StrapiResponse<T>,
  log?: boolean
): T[] {
  if (log) console.info("formatStrapiResponseData:", data.data);
  return data.data.map((item) => {
    deleteField(item.attributes, "formats");
    return item.attributes;
  });
}

function formatStrapiSingleTypeResponseData<T>(
  data: StrapiSingleTypeResponse<T>,
  log?: boolean
): T {
  if (log) console.info(data.data);
  data.data.attributes && deleteField(data.data.attributes, "formats");
  return data.data.attributes;
}
function populate(toPopulate: string[]): string {
  return toPopulate.map((item) => `populate=${item}`).join("&");
}

export function getStrapiMediaUrl(media: StrapiMediaAttributes): string {
  if (media?.url.startsWith("https://")) return media.url;
  return `${process.env.NEXT_PUBLIC_STRAPI_URL}${media?.url}`;
}

/*
 * MODELS
 */

async function getPlaces(locale: string): Promise<PlaceType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PlaceType>(
      `/places?locale=${locale}&${populate(["city"])}`
    )
  );
}

async function getAllPlaces(
  places: PlaceType[],
  locale: string,
  updatedAt?: Date
): Promise<PlaceType[]> {
  updatedAt = updatedAt || new Date(0);
  //console.log("getAllCities", updatedAt);
  const response: StrapiResponse<PlaceType> = await fetchStrapiAPI<PlaceType>(
    `/places?locale=${locale}&${populate([
      "city",
    ])}&pagination[limit]=100&pagination[start]=${
      places.length
    }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
  );
  const results: PlaceType[] = [
    ...places,
    ...formatStrapiResponseData(response),
  ];
  console.log(results.length, "places", "/", response.meta.pagination.total);
  /*   if (results.length > 200) {
    return results;
  } */

  if (results.length >= response.meta.pagination.total) return results;
  return getAllPlaces(results, locale);
}

async function getPlace(locale: string, slug = "default"): Promise<PlaceType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PlaceType>(
      `/places?${formatEqFilter("slug", slug)}&locale=${locale}&${populate([
        "city",
      ])}`
    )
  )[0];
}

async function getCities(locale: string): Promise<CityType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<CityType>(`/cities?locale=${locale}`)
  );
}

async function getAllCities(
  cities: CityType[],
  locale: string,
  updatedAt?: Date
): Promise<CityType[]> {
  updatedAt = updatedAt || new Date(0);
  //console.log("getAllCities", updatedAt);
  const response: StrapiResponse<CityType> = await fetchStrapiAPI<CityType>(
    `/cities?locale=${locale}&${populate([
      "places",
    ])}&pagination[limit]=100&pagination[start]=${
      cities.length
    }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
  );
  const results: CityType[] = [
    ...cities,
    ...formatStrapiResponseData(response),
  ];
  console.log(results.length, "cities", "/", response.meta.pagination.total);
  /*   if (results.length > 200) {
    return results;
  } */

  if (results.length >= response.meta.pagination.total) return results;
  return getAllCities(results, locale);
}

async function getCity(locale: string, slug = "default"): Promise<CityType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<CityType>(
      `/cities?${formatEqFilter("slug", slug)}&locale=${locale}&${populate([
        "places",
      ])}`
    )
  )[0];
}

async function getVTCPages(locale: string): Promise<PageBaseType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-vtcs?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getVTCPage(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  const data = await fromCache<PageBaseType>(`page-vtc-${slug}`);
  if (data) return data;
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-vtcs?${formatEqFilter("slug", slug)}&locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  )[0];
}

async function getAllVTCPages(
  results: PageBaseType[],
  locale: string,
  updatedAt?: Date
): Promise<PageBaseType[]> {
  updatedAt = updatedAt || new Date(0);
  //console.log("getAllVTCPages");
  const response: StrapiResponse<PageBaseType> =
    await fetchStrapiAPI<PageBaseType>(
      `/page-vtcs?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}&pagination[start]=${
        results.length
      }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
    );
  const newResults: PageBaseType[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "VTCPages",
    "/",
    response.meta.pagination.total
  );
  /*   return newResults; */
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllVTCPages(newResults, locale);
}

async function getPageInfo(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/pages-infos?${formatEqFilter("slug", slug)}&locale=${locale}&${populate(
        ["seo", "sections", "reassurances"]
      )}`
    )
  )[0];
}

async function getAllPagesInfo(
  results: PageBaseType[],
  locale: string
): Promise<PageBaseType[]> {
  const response: StrapiResponse<PageBaseType> =
    await fetchStrapiAPI<PageBaseType>(
      `/pages-infos?locale=${locale}&${populate([
        "seo",
        "sections",
        "reassurances",
      ])}&pagination[start]=${results.length}`
    );
  const newResults: PageBaseType[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "PagesInfos",
    "/",
    response.meta.pagination.total
  );
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllPagesInfo(newResults, locale);
}

async function getPageArticle(
  locale: string,
  slug = "default",
  draft = false
): Promise<PageArticleType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageArticleType>(
      `/blog-articles?${formatEqFilter("slug", slug)}&publicationState=${
        draft ? "preview" : "live"
      }&locale=${locale}&${populate([
        "seo",
        "categories",
        "coverImage",
        "cta",
      ])}`
    )
  )[0];
}

async function getAllArticles(
  results: PageArticleType[],
  locale: string
): Promise<PageArticleType[]> {
  const response: StrapiResponse<PageArticleType> =
    await fetchStrapiAPI<PageArticleType>(
      `/blog-articles?locale=${locale}&${populate([
        "seo",
        "categories",
        "coverImage",
      ])}&pagination[start]=${results.length}&sort=date:desc`
    );
  const newResults: PageArticleType[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "Articles",
    "/",
    response.meta.pagination.total
  );
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllArticles(newResults, locale);
}

async function getArticles(
  locale: string,
  excludeSlugs: string[] = [],
  maxLength: number = 10
): Promise<PageArticleType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageArticleType>(
      `/blog-articles?locale=${locale}&${populate([
        "seo",
        "categories",
        "coverImage",
      ])}&_limit=${maxLength + excludeSlugs.length}&sort=date:desc`
    )
  )
    .filter((article) => !excludeSlugs.includes(article.slug))
    .slice(0, maxLength);
}

async function getArticleCategory(
  locale: string,
  slug = "default"
): Promise<ArticleCategory> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<ArticleCategory>(
      `/blog-categories?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["seo", "coverImage"])}`
    )
  )[0];
}

async function getArticleCategories(
  results: ArticleCategory[],
  locale: string
): Promise<ArticleCategory[]> {
  const response: StrapiResponse<ArticleCategory> =
    await fetchStrapiAPI<ArticleCategory>(
      `/blog-categories?locale=${locale}&${populate([
        "seo",
        "coverImage",
      ])}&pagination[start]=${results.length}`
    );
  const newResults: ArticleCategory[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getArticleCategories(newResults, locale);
}

async function getTaxiPages(locale: string): Promise<PageBaseType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-taxis?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getAllTaxiPages(
  results: PageBaseType[],
  locale: string,
  updatedAt?: Date
): Promise<PageBaseType[]> {
  updatedAt = updatedAt || new Date(0);
  const response: StrapiResponse<PageBaseType> =
    await fetchStrapiAPI<PageBaseType>(
      `/page-taxis?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}&pagination[start]=${
        results.length
      }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
    );
  const newResults: PageBaseType[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "TaxiPage",
    "/",
    response.meta.pagination.total
  );
  /*   return newResults; */
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllTaxiPages(newResults, locale);
}

async function getTaxiPage(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  const data = await fromCache<PageBaseType>(`page-taxi-${slug}`);
  if (data) return data;
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-taxis?${formatEqFilter("slug", slug)}&locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  )[0];
}

async function getMotoTaxiPages(locale: string): Promise<PageBaseType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-moto-taxis?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getMotoTaxiPage(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-moto-taxis?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["sections", "seo", "reassurances"])}`
    )
  )[0];
}

async function getAllMotoTaxiPages(
  results: PageBaseType[],
  locale: string,
  updatedAt?: Date
): Promise<PageBaseType[]> {
  updatedAt = updatedAt || new Date(0);
  const response: StrapiResponse<PageBaseType> =
    await fetchStrapiAPI<PageBaseType>(
      `/page-moto-taxis?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}&pagination[start]=${
        results.length
      }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
    );
  const newResults: PageBaseType[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "MotoTaxiPage",
    "/",
    response.meta.pagination.total
  );
  /*   return newResults; */
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllMotoTaxiPages(newResults, locale);
}

async function getPMRPages(locale: string): Promise<PageBaseType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-pmrs?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getPMRPage(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-pmrs?${formatEqFilter("slug", slug)}&locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  )[0];
}

async function getPlacePages(locale: string): Promise<PageBaseType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-places?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getAllPlacePages(
  pages: PageBaseType[],
  locale: string,
  updatedAt?: Date
): Promise<PageBaseType[]> {
  updatedAt = updatedAt || new Date(0);
  //console.log("getAllCities", updatedAt);
  const response: StrapiResponse<PageBaseType> =
    await fetchStrapiAPI<PageBaseType>(
      `/page-places?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}&pagination[limit]=100&pagination[start]=${
        pages.length
      }&filters[updatedAt][$gte]=${updatedAt.toISOString()}`
    );
  const results: PageBaseType[] = [
    ...pages,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    results.length,
    "place page",
    "/",
    response.meta.pagination.total
  );
  /*   if (results.length > 200) {
    return results;
  } */

  if (results.length >= response.meta.pagination.total) return results;
  return getAllPlacePages(results, locale);
}

async function getPlacePage(
  locale: string,
  slug = "default"
): Promise<PageBaseType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<PageBaseType>(
      `/page-places?${formatEqFilter("slug", slug)}&locale=${locale}&${populate(
        ["sections", "seo", "reassurances"]
      )}`
    )
  )[0];
}

async function getCardsSections(locale: string): Promise<CardsSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<CardsSectionType>(
      `/section-cards?locale=${locale}&${populate(["cards", "cards.image"])}`
    )
  );
}

async function getCardsSection(
  locale: string,
  slug = "default"
): Promise<CardsSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<CardsSectionType>(
      `/section-cards?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["cards", "cards.image", "cta"])}`
    )
  )[0];
}

async function getDatasSection(
  locale: string,
  slug = "default"
): Promise<DatasSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<DatasSectionType>(
      `/section-datas?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["data", "image"])}`
    )
  )[0];
}

async function getContentSections(
  locale: string
): Promise<ContentSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<ContentSectionType>(
      `/section-contents?locale=${locale}&${populate([])}`
    )
  );
}

async function getContentSection(
  locale: string,
  slug = "default"
): Promise<ContentSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<ContentSectionType>(
      `/section-contents?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate([])}`
    )
  )[0];
}

async function getLinksSections(locale: string): Promise<LinksSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LinksSectionType>(
      `/section-links?locale=${locale}&${populate(["links", "links.image"])}`
    )
  );
}

async function getLinksSection(
  locale: string,
  slug = "default"
): Promise<LinksSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LinksSectionType>(
      `/section-links?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["links", "links.image"])}`
    )
  )[0];
}

async function getLogosSections(locale: string): Promise<LogosSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LogosSectionType>(
      `/section-logos?locale=${locale}&${populate(["logos"])}`
    )
  );
}

async function getLogosSection(
  locale: string,
  slug = "default"
): Promise<LogosSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LogosSectionType>(
      `/section-logos?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["logos"])}`
    )
  )[0];
}

async function getRatingsSections(
  locale: string
): Promise<RatingsSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<RatingsSectionType>(
      `/section-ratings?locale=${locale}&${populate([
        "ratings",
        "ratings.image",
      ])}`
    )
  );
}

async function getRatingsSection(
  locale: string,
  slug = "default"
): Promise<RatingsSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<RatingsSectionType>(
      `/section-ratings?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["ratings", "ratings.image"])}`
    )
  )[0];
}

async function getSimplesSections(
  locale: string
): Promise<SimplesSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<SimplesSectionType>(
      `/section-simples?locale=${locale}&${populate(["image"])}`
    )
  );
}

async function getSimplesSection(
  locale: string,
  slug = "default"
): Promise<SimplesSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<SimplesSectionType>(
      `/section-simples?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["image"])}`
    )
  )[0];
}

async function getFoldableSections(
  locale: string
): Promise<FoldableSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<FoldableSectionType>(
      `/section-foldables?locale=${locale}&${populate(["folds"])}`
    )
  );
}

async function getFoldableSection(
  locale: string,
  slug = "default"
): Promise<FoldableSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<FoldableSectionType>(
      `/section-foldables?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["folds"])}`
    )
  )[0];
}

async function getTitlesSections(locale: string): Promise<TitlesSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TitlesSectionType>(
      `/section-titles?locale=${locale}&${populate(["image", "cta", "logos"])}`
    )
  );
}

async function getTitlesSection(
  locale: string,
  slug = "default"
): Promise<TitlesSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TitlesSectionType>(
      `/section-titles?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["image", "cta", "logos"])}`
    )
  )[0];
}

async function getRSETitlesSections(
  locale: string
): Promise<RSETitleSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<RSETitleSectionType>(
      `/section-rse-titles?locale=${locale}&${populate([
        "image",
        "banner",
        "banner.leftContent",
        "banner.middleContent",
        "banner.rightContent",
        "button",
      ])}`
    )
  );
}

async function getRSETitlesSection(
  locale: string,
  slug = "default"
): Promise<RSETitleSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<RSETitleSectionType>(
      `/section-rse-titles?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate([
        "image",
        "banner",
        "banner.leftContent",
        "banner.middleContent",
        "banner.rightContent",
        "button",
      ])}`
    )
  )[0];
}

async function getTransfertSections(
  locale: string
): Promise<TransfertsSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TransfertsSectionType>(
      `/section-transferts?locale=${locale}&${populate([
        "cards",
        "cards.image",
      ])}`
    )
  );
}

async function getTransfertSection(
  locale: string,
  slug = "default"
): Promise<TransfertsSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TransfertsSectionType>(
      `/section-transferts?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["cards", "cards.image"])}`
    )
  )[0];
}

async function getLargeCardsSections(
  locale: string
): Promise<LargeCardsSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LargeCardsSectionType>(
      `/section-large-cards?locale=${locale}&${populate([
        "cards",
        "cards.image",
      ])}`
    )
  );
}

async function getLargeCardsSection(
  locale: string,
  slug = "default"
): Promise<LargeCardsSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<LargeCardsSectionType>(
      `/section-large-cards?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["cards", "cards.image"])}`
    )
  )[0];
}

async function getColorColumnsSections(
  locale: string
): Promise<TwoColorColumnsSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TwoColorColumnsSectionType>(
      `/section-color-columns?locale=${locale}&${populate([
        "blueColumn",
        "blueColumn.rightCard",
        "blueColumn.leftCard",
        "greenColumn",
        "greenColumn.rightCard",
        "greenColumn.leftCard",
      ])}`
    )
  );
}

async function getColorColumnsSection(
  locale: string,
  slug = "default"
): Promise<TwoColorColumnsSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<TwoColorColumnsSectionType>(
      `/section-color-columns?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate([
        "blueColumn",
        "blueColumn.rightCard",
        "blueColumn.leftCard",
        "greenColumn",
        "greenColumn.rightCard",
        "greenColumn.leftCard",
      ])}`
    )
  )[0];
}

async function getMapsCardSections(
  locale: string
): Promise<MapsCardSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<MapsCardSectionType>(
      `/section-maps-cards?locale=${locale}&${populate([
        "cards",
        "cards.image",
        "cards.meetupPoints",
      ])}`
    )
  );
}

async function getMapsCardSection(
  locale: string,
  slug = "default"
): Promise<MapsCardSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<MapsCardSectionType>(
      `/section-maps-cards?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate([
        "cards",
        "cards.image",
        "cards.meetupPoints",
      ])}`
    )
  )[0];
}

async function getMobileAppBanner(
  locale: string
): Promise<MobileAppBannerType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<MobileAppBannerType>(
      `/mobile-app-banner?locale=${locale}&${populate(["logo"])}`
    )
  );
}

async function getNewsletterBanner(
  locale: string
): Promise<NewsLetterBannerType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<NewsLetterBannerType>(
      `/newsletter-banner?locale=${locale}&${populate(["image"])}`
    )
  );
}

async function getEcologyBanner(locale: string): Promise<EcologyBannerType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<EcologyBannerType>(
      `/ecology-banner?locale=${locale}&${populate([
        "image",
        "button",
        "button.image",
      ])}`
    )
  );
}

async function getContactUs(locale: string): Promise<ContactUsSectionType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<ContactUsSectionType>(
      `/contact-us?locale=${locale}&${populate([])}`
    ),
    true
  );
}

async function getImageBannerSections(
  locale: string
): Promise<ImageBannerSectionType[]> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<ImageBannerSectionType>(
      `/image-banners?locale=${locale}&${populate(["image", "button", "cta"])}`
    )
  );
}

async function getImageBannerSection(
  locale: string,
  slug = "default"
): Promise<ImageBannerSectionType> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<ImageBannerSectionType>(
      `/image-banners?${formatEqFilter(
        "slug",
        slug
      )}&locale=${locale}&${populate(["image", "button", "cta"])}`
    )
  )[0];
}

async function getFooter(locale: string): Promise<FooterSectionType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<FooterSectionType>(
      `/footer?locale=${locale}&${populate(["columns", "columns.links"])}`
    )
  );
}

async function getNavigationBar(locale: string): Promise<NavigationBarType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<NavigationBarType>(
      `/navigation-bar?&locale=${locale}&${populate([
        "logo",
        "links",
        "bookButton",
      ])}`
    )
  );
}

async function getHomePage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-home?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getAvantagesPage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-avantage?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getDifferencePage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-differences-vtc-taxi?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getEntreprisesPage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-entreprises?locale=${locale}&${populate([
        "sections",
        "seo",
        "reassurances",
      ])}`
    )
  );
}

async function getRsePage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-rse?locale=${locale}&${populate(["sections", "seo"])}`
    )
  );
}

async function getRseEnvironnementPage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-rse-environnement?locale=${locale}&${populate([
        "sections",
        "seo",
      ])}`
    )
  );
}

async function getRseTravailPage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-rse-travail?locale=${locale}&${populate(["sections", "seo"])}`
    )
  );
}

async function getRseEthiquePage(locale: string): Promise<PageBaseType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBaseType>(
      `/page-rse-ethique?locale=${locale}&${populate(["sections", "seo"])}`
    )
  );
}

async function getPageCGS(locale: string): Promise<PageInfoType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageInfoType>(
      `/page-cgu?locale=${locale}&${populate(["seo"])}`
    )
  );
}

async function getPageMentionsLegales(locale: string): Promise<PageInfoType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageInfoType>(
      `/page-mention-legale?locale=${locale}&${populate(["seo"])}`
    )
  );
}

async function getPageCharteQualite(locale: string): Promise<PageInfoType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageInfoType>(
      `/page-charte-qualite?locale=${locale}&${populate(["seo"])}`
    )
  );
}

async function getPagePersonalDataProtection(
  locale: string
): Promise<PageInfoType> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageInfoType>(
      `/page-protection-des-donnees-personnelle?locale=${locale}&${populate([
        "seo",
      ])}`
    )
  );
}

async function getPageBlog(locale: string): Promise<PageBlog> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageBlog>(
      `/page-blog?locale=${locale}&${populate([
        "seo",
        "sliderArticles",
        "sliderArticles.categories",
        "sliderArticles.coverImage",
        "categoriesToDisplay",
        "categoriesToDisplay.coverImage",
        "sliderBackground",
        "blogNews",
      ])}`
    )
  );
}

async function getAnnuairePage(locale: string): Promise<PageAnnuaire> {
  return formatStrapiSingleTypeResponseData(
    await fetchStrapiSingleTypeAPI<PageAnnuaire>(
      `/page-annuaire?locale=${locale}&${populate(["seo"])}`
    ),
    true
  );
}

async function getSection(
  type: SectionTypeEnum,
  slug: string,
  locale = "fr"
): Promise<PageSectionType<SectionsCollectionType> | null> {
  switch (type) {
    case SectionTypeEnum.CARDS_SECTION:
      return {
        name: type,
        section: await getCardsSection(locale, slug),
      } as PageSectionType<CardsSectionType>;
    case SectionTypeEnum.LINKS_SECTION:
      return {
        name: type,
        section: await getLinksSection(locale, slug),
      } as PageSectionType<LinksSectionType>;
    case SectionTypeEnum.LOGOS_SECTION:
      return {
        name: type,
        section: await getLogosSection(locale, slug),
      } as PageSectionType<LogosSectionType>;
    case SectionTypeEnum.RATINGS_SECTION:
      return {
        name: type,
        section: await getRatingsSection(locale, slug),
      } as PageSectionType<RatingsSectionType>;
    case SectionTypeEnum.SIMPLES_SECTION:
      return {
        name: type,
        section: await getSimplesSection(locale, slug),
      } as PageSectionType<SimplesSectionType>;
    case SectionTypeEnum.TITLES_SECTION:
      return {
        name: type,
        section: await getTitlesSection(locale, slug),
      } as PageSectionType<TitlesSectionType>;
    case SectionTypeEnum.FOLDABLE_SECTION:
      return {
        name: type,
        section: await getFoldableSection(locale, slug),
      } as PageSectionType<FoldableSectionType>;
    case SectionTypeEnum.TRANSFERTS_SECTION:
      return {
        name: type,
        section: await getTransfertSection(locale, slug),
      } as PageSectionType<TransfertsSectionType>;
    case SectionTypeEnum.MAPS_CARD_SECTION:
      return {
        name: type,
        section: await getMapsCardSection(locale, slug),
      } as PageSectionType<MapsCardSectionType>;
    case SectionTypeEnum.LARGE_CARDS_SECTION:
      return {
        name: type,
        section: await getLargeCardsSection(locale, slug),
      } as PageSectionType<LargeCardsSectionType>;
    case SectionTypeEnum.IMAGE_BANNERS:
      return {
        name: type,
        section: await getImageBannerSection(locale, slug),
      } as PageSectionType<ImageBannerSectionType>;
    case SectionTypeEnum.TWO_COLOR_COLUMNS_SECTION:
      return {
        name: type,
        section: await getColorColumnsSection(locale, slug),
      } as PageSectionType<TwoColorColumnsSectionType>;
    case SectionTypeEnum.RSE_TITLES_SECTION:
      return {
        name: type,
        section: await getRSETitlesSection(locale, slug),
      } as PageSectionType<RSETitleSectionType>;
    default:
      return null;
  }
}

export async function getSectionSingleType(
  type: SectionTypeEnum,
  locale = "fr"
): Promise<PageSectionType<SectionsSingleType> | null> {
  switch (type) {
    case SectionTypeEnum.MOBILE_APP_BANNER:
      return {
        name: type,
        section: await getMobileAppBanner(locale),
      } as PageSectionType<MobileAppBannerType>;
    case SectionTypeEnum.NAVIGATION_BAR:
      return {
        name: type,
        section: await getNavigationBar(locale),
      } as PageSectionType<NavigationBarType>;
    case SectionTypeEnum.FOOTER:
      return {
        name: type,
        section: await getFooter(locale),
      } as PageSectionType<FooterSectionType>;
    case SectionTypeEnum.ECOLOGY_BANNER:
      return {
        name: type,
        section: await getEcologyBanner(locale),
      } as PageSectionType<EcologyBannerType>;
    case SectionTypeEnum.NEWSLETTERS_BANNER:
      return {
        name: type,
        section: await getNewsletterBanner(locale),
      } as PageSectionType<NewsLetterBannerType>;
    case SectionTypeEnum.CONTACT_US:
      return {
        name: type,
        section: await getContactUs(locale),
      } as PageSectionType<ContactUsSectionType>;
    default:
      return null;
  }
}

export async function getAllSectionFromPage(
  page: PageBaseType,
  slugPrefix = ""
): Promise<(PageSectionType<SectionsType> | null)[]> {
  return Promise.all(
    page.sections.map(async (section) => {
      if (!section) return null;
      if (section.strapiType == SectionStrapiTypeEnum.SINGLE) {
        return StrapiApi.single_types.section(section.typeName);
      }
      let res =
        section.slug && section.slug.length > 0
          ? await StrapiApi.collection_types.section(
              section.typeName,
              section.slug
            )
          : null;
      if ((!res || !res.section) && slugPrefix && slugPrefix.length > 0) {
        res = await StrapiApi.collection_types.section(
          section.typeName,
          `${slugPrefix}default`
        );
      }
      if (!res || !res.section) {
        res = await StrapiApi.collection_types.section(
          section.typeName,
          "default"
        );
      }
      return res;
    })
  );
}

async function getLandingPage(
  slug: string,
  draft = false
): Promise<LandingPage> {
  return formatStrapiResponseData(
    await fetchStrapiAPI<any>(
      `/page-landing-pages?${formatEqFilter(
        "slug",
        slug
      )}&populate=deep&publicationState=${draft ? "preview" : "live"}`
    )
  )[0];
}

type LandingPageSlug = {
  slug: string;
};

async function getAllLandingPagesSlugs(
  results: LandingPageSlug[]
): Promise<LandingPageSlug[]> {
  const response: StrapiResponse<LandingPageSlug> = await fetchStrapiAPI<any>(
    `/page-landing-pages?fields[0]=slug&pagination[start]=${results.length}&sort=date:desc`
  );
  const newResults: LandingPageSlug[] = [
    ...results,
    ...formatStrapiResponseData(response),
  ];
  console.log(
    newResults.length,
    "Landing Pages",
    "/",
    response.meta.pagination.total
  );
  if (newResults.length >= response.meta.pagination.total) return newResults;
  return getAllLandingPagesSlugs(newResults);
}

export const StrapiApi = {
  collection_types: {
    cardsSection: getCardsSection,
    cardsSections: getCardsSections,
    datasSection: getDatasSection,
    contentSection: getContentSection,
    contentSections: getContentSections,
    linksSection: getLinksSection,
    linksSections: getLinksSections,
    logosSection: getLogosSection,
    logosSections: getLogosSections,
    ratingsSection: getRatingsSection,
    ratingsSections: getRatingsSections,
    simplesSection: getSimplesSection,
    simplesSections: getSimplesSections,
    foldableSection: getFoldableSection,
    foldableSections: getFoldableSections,
    titlesSection: getTitlesSection,
    titlesSections: getTitlesSections,
    rseTitlesSection: getRSETitlesSection,
    rseTitlesSections: getRSETitlesSections,
    VTCPage: getVTCPage,
    VTCPages: getVTCPages,
    allVTCPages: getAllVTCPages,
    motoTaxiPage: getMotoTaxiPage,
    motoTaxiPages: getMotoTaxiPages,
    allMotoTaxiPages: getAllMotoTaxiPages,
    taxiPage: getTaxiPage,
    taxiPages: getTaxiPages,
    allTaxiPages: getAllTaxiPages,
    PMRPage: getPMRPage,
    PMRPages: getPMRPages,
    placePage: getPlacePage,
    placePages: getPlacePages,
    allPlacePages: getAllPlacePages,
    place: getPlace,
    places: getPlaces,
    allPlaces: getAllPlaces,
    transfertSection: getTransfertSection,
    transfertSections: getTransfertSections,
    mapsCardSection: getMapsCardSection,
    mapsCardSections: getMapsCardSections,
    largeCardsSection: getLargeCardsSection,
    largeCardsSections: getLargeCardsSections,
    imageBanner: getImageBannerSection,
    imageBanners: getImageBannerSections,
    colorColumnsSection: getColorColumnsSection,
    colorColumnsSections: getColorColumnsSections,
    city: getCity,
    cities: getCities,
    allCities: getAllCities,
    section: getSection,
    pageInfo: getPageInfo,
    allPagesInfo: getAllPagesInfo,
    pageArticle: getPageArticle,
    allArticles: getAllArticles,
    getArticles: getArticles,
    articleCategory: getArticleCategory,
    articleCategories: getArticleCategories,
    landingPage: getLandingPage,
    allLandingPagesSlugs: getAllLandingPagesSlugs,
  },
  single_types: {
    footer: getFooter,
    mobileAppBanner: getMobileAppBanner,
    navigationBar: getNavigationBar,
    homePage: getHomePage,
    avantagesPage: getAvantagesPage,
    entreprisesPage: getEntreprisesPage,
    rsePage: getRsePage,
    rseEnvironnementPage: getRseEnvironnementPage,
    rseTravailPage: getRseTravailPage,
    rseEthiquePage: getRseEthiquePage,
    newsletterBanner: getNewsletterBanner,
    ecologyBanner: getEcologyBanner,
    contactUs: getContactUs,
    section: getSectionSingleType,
    pageCGS: getPageCGS,
    pageMentionsLegales: getPageMentionsLegales,
    pageCharteQualite: getPageCharteQualite,
    differencePage: getDifferencePage,
    personalDataProtection: getPagePersonalDataProtection,
    pageBlog: getPageBlog,
    annuairePage: getAnnuairePage,
  },
};

export const deleteField = (obj: any, field: string) => {
  if (!obj) return;
  Object.keys(obj).forEach((key) => {
    if (key === field) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      deleteField(obj[key], field);
    }
  });
};
