import React from 'react';
import tw from 'tailwind-styled-components';
import { Button } from '../../../components';

export function Section404(): JSX.Element {
  return (
    <Main>
      <Title style={{ fontSize: '5rem' }}>404</Title>
      <Description style={{ fontSize: '1.5rem' }}>
        Cette page n'existe pas
      </Description>
      <Button className='mr-0' color='green' href='/'>
        Retour à l'accueil
      </Button>
    </Main>
  );
}

const Main = tw.div`
  grid
  content-center
  justify-center
  py-24
`;

const Title = tw.h1`
font-extrabold
text-center
`;

const Description = tw.h1`
text-center
mb-12
`;
