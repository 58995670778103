import React from 'react';
import tw from 'tailwind-styled-components';
import { HeaderSection } from '../../../components';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { LinksSectionType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import { Image } from '../../../components';

interface LinksSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: LinksSectionType;
}

export function LinksSection(props: LinksSectionProps): JSX.Element {
  const { data, variables } = props;
  return (
    <Main>
      <HeaderSection
        title={textCompiler(data.title, variables)}
        text={textCompiler(data.text, variables)}
      />
      <LinksContainer>
        {data.links?.map((link) => (
          <LinkContainer href={link.url} key={link.id}>
            {link.image.data ? (
              <ImageStyled
                src={getStrapiMediaUrl(link.image.data.attributes)}
                alt={link.image.data.attributes.name}
              />
            ) : (
              <NoImage />
            )}

            <Text>{textCompiler(link.text, variables)}</Text>
          </LinkContainer>
        ))}
      </LinksContainer>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lm:px-0 
`;

const LinksContainer = tw.div`
  grid
  grid-cols-12
`;

const LinkContainer = tw.a`
  m-2
  col-span-6
  lg:col-span-4
  flex
  items-center
`;

const ImageStyled = tw(Image)`
  w-10 
  h-10
`;

const NoImage = tw.div`
  w-10 
  h-10
  bg-grey
`;

const Text = tw.p`
  text-base 
  ml-4
  bob-link-hover-underline  
`;
