import React from 'react';
import tw from 'tailwind-styled-components';

interface BadgeCircleProps {
  className?: string;
  size: number;
}

export function BadgeCircle(props: BadgeCircleProps): JSX.Element {
  const { className, size } = props;

  return <Main className={`${className} w-${size} h-${size}`} />;
}

const Main = tw.div`
  bg-primary
  rounded-full
`;
