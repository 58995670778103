import React from 'react';
import tw from 'tailwind-styled-components';
import { Button, HeaderSection, Image } from '../../../components';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { EcologyBannerType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';

interface EcologySectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: EcologyBannerType;
}

export function EcologySection(props: EcologySectionProps): JSX.Element {
  const { data, variables, isFullScreen } = props;

  return (
    <Main
      $isFullScreen={isFullScreen}
      style={{ backgroundColor: data.backgroundColor }}
    >
      <LeftCol>
        <HeaderSection
          title={textCompiler(data.title, variables)}
          text={textCompiler(data.text, variables)}
        />
        <Button
          href={data.button.url}
          rightIcon='rightChevron.svg'
          styled='w-fit'
          color='green'
        >
          {textCompiler(data.button.text, variables)}
        </Button>
      </LeftCol>
      <RightCol>
        {data.image.data && (
          <Image
            src={getStrapiMediaUrl(data.image.data.attributes)}
            alt='ecologyImg'
          />
        )}
      </RightCol>
    </Main>
  );
}

const Main = tw.div<{ $isFullScreen?: boolean }>`
  items-center
  justify-between
  my-16
  lg:py-8
  p-6
  grid
  grid-cols-3
    ${(props: { $isFullScreen?: boolean }) => props.$isFullScreen && 'lg:px-60'}
`;

const LeftCol = tw.div`
  col-span-3
  lg:col-span-2
  order-2
  lg:order-1
`;

const RightCol = tw.div`
  col-span-3
  lg:col-span-1
  order-1
  lg:order-2
  pb-4
  lg:pb-0
`;
