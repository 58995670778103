import React from "react";
import tw from "tailwind-styled-components";
import { markdownToHtml } from "../../services/markdown";
import { PageVariables } from "../../services/pageVariables";
import { getStrapiMediaUrl } from "../../services/strapiApi";
import { textCompiler } from "../../services/textCompiler";
import { CardsSectionCard } from "../../types";
import { Image } from "../Image";

interface CardProps {
	card: CardsSectionCard;
	variables?: PageVariables;
}

export function Card(props: CardProps): JSX.Element {
	const { card, variables } = props;

	return (
		<Main>
			{card && card.image && card.image.data && (
				<ImageContainer>
					<ImageStyled
						src={getStrapiMediaUrl(card.image.data.attributes)}
						alt={card.title}
					/>
				</ImageContainer>
			)}
			<TextContainer>
				{card.title && (
					<Title>
						<Content
							dangerouslySetInnerHTML={{
								__html: markdownToHtml(textCompiler(card.title, variables)),
							}}
							className='break-words'
							style={{ color: card.titleColor && card.titleColor }}
						/>
					</Title>
				)}
				{card.text && (
					<Text>
						<Content
							dangerouslySetInnerHTML={{
								__html: markdownToHtml(textCompiler(card.text, variables)),
							}}
							className='prose break-words'
						/>
					</Text>
				)}
			</TextContainer>
		</Main>
	);
}

const Main = tw.div`
col-span-1
min-w-[250px]
max-w-[250px]
w-[250px]
flex flex-col justify-evenly
`;

const ImageContainer = tw.div`
w-full
`;

const ImageStyled = tw(Image)`
w-full
`;

const TextContainer = tw.div`
flex
flex-col
justify-between
items-center
`;

const Title = tw.h2`
  text-base
  font-medium	
  lg:text-lg
  mb-2
  break-words
`;

const Text = tw.span`
  flex
  text-sm
  text-grey
  text-left	
  w-full
  break-words
`;

const Content = tw.span`
w-full min-w-full
`;
