import React from 'react';
import tw from 'tailwind-styled-components';
import { generateArrayOfNElements } from '../../services/array';
import { BadgeCircle } from '../Badge';

interface PaginationBadgeProps {
  className?: string;
  length: number;
  focused: number;
  badgeSize?: number;
  spacing?: number;
}

export function PaginationBadge(props: PaginationBadgeProps): JSX.Element {
  const { className, length, badgeSize, focused, spacing } = props;

  const size = badgeSize ?? 2;

  return (
    <Main className={className}>
      {generateArrayOfNElements({}, length).map((_, i) => (
        <BadgeCircle
          key={i}
          size={size}
          className={`mr-${spacing ?? 2} last:mr-0 ${
            focused === i ? 'bg-greenLight' : 'bg-gray-200'
          }`}
        />
      ))}
    </Main>
  );
}

const Main = tw.div`
  flex
  flex-row
  h-8
`;
