import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import {
  FooterSectionColumn,
  FooterSectionType,
  LinksSectionLink,
} from "../../../types";
import { PageVariables } from "../../../services/pageVariables";
import { Image } from "../../../components";
import { useRouter } from "next/router";

interface FooterSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: FooterSectionType;
}

export function FooterSection(props: FooterSectionProps): JSX.Element {
  const { data } = props;
  const defaultLocale = "fr"; // Langue par défaut
  const router = useRouter();

  const [language, setLanguage] = useState(defaultLocale);

  useEffect(() => {
    // Déterminer la langue en fonction de l'URL
    const detectedLanguage = /(^|\/)en(\/|$)/.test(router.asPath)
      ? "en"
      : defaultLocale;

    // Stocker la langue dans le local storage
    localStorage.setItem("language", detectedLanguage);

    // Mettre à jour l'état local pour refléter la langue détectée
    setLanguage(detectedLanguage);
  }, [router.asPath]);

  useEffect(() => {
    // Vérifier s'il existe déjà une langue dans le local storage au chargement
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  return (
    <Main>
      <div className="max-w-screen-2xl mx-auto lg:px-8">
        <DynamicFooter>
          <LogoCol>
            <StyledLogo src="/allocabLogo.svg" alt="allocabLogo" />
          </LogoCol>
          {data.columns.map((col: FooterSectionColumn) => (
            <Col key={language === "fr" ? col.title : col.titleEN}>
              <Title>{language === "fr" ? col.title : col.titleEN}</Title>
              <LinksContainer>
                {col.links.map((link: LinksSectionLink) => (
                  <Link
                    target={link.newWindow ? "_blank" : ""}
                    rel={link.newWindow ? "noopener noreferrer" : ""}
                    href={language === "fr" ? link.url : link.urlEN}
                    key={link.id}
                  >
                    {language === "fr" ? link.text : link.textEN}
                  </Link>
                ))}
              </LinksContainer>
            </Col>
          ))}
        </DynamicFooter>
        <StaticFooter>
          <Copyright>© Allocab {new Date().getFullYear()}</Copyright>
          <SocialNetworksContainer>
            <SocialNetwork
              target="_blank"
              rel="noopener noreferrer"
              href={data.facebook}
            >
              <img src={"/socialMedia/facebook.svg"} alt={"facebook"} />
            </SocialNetwork>
            <SocialNetwork
              target="_blank"
              rel="noopener noreferrer"
              href={data.linkedIn}
            >
              <img src={"/socialMedia/linkedin.svg"} alt={"linkedin"} />
            </SocialNetwork>
            <SocialNetwork
              target="_blank"
              rel="noopener noreferrer"
              href={data.twitter}
            >
              <img src={"/socialMedia/twitter.svg"} alt={"twitter"} />
            </SocialNetwork>
            <SocialNetwork
              target="_blank"
              rel="noopener noreferrer"
              href={data.youtube}
            >
              <img src={"/socialMedia/youtube.svg"} alt={"youtube"} />
            </SocialNetwork>
            <SocialNetwork
              target="_blank"
              rel="noopener noreferrer"
              href={data.instagram}
            >
              <img src={"/socialMedia/instagram.svg"} alt={"instagram"} />
            </SocialNetwork>
          </SocialNetworksContainer>
        </StaticFooter>
      </div>
    </Main>
  );
}

const Main = tw.div`
  bg-lightGrey
  px-6
  pb-10
  lg:pb-0
`;

const DynamicFooter = tw.div`
  grid
  lg:grid-cols-4
  grid-cols-2
  py-14
`;

const LogoCol = tw.div`
  col-span-2
  lg:col-span-1
  mt-8
  lg:mt-0
`;

const Col = tw.div`
  col-span-1
  mt-8
  lg:mt-0
`;

const Title = tw.p`
  text-base
`;

const LinksContainer = tw.div`
  flex
  flex-col
  mt-4
`;

const Link = tw.a`
  mt-2
  text-sm
  text-grey
  hover:underline 
  hover:text-blue
`;

const StaticFooter = tw.div`
  flex
  justify-between
  flex-col-reverse
  lg:flex-row
  pb-12
  lg:pb-0
`;

const Copyright = tw.div`
  px-32
  lg:px-0
w-28
lg:w-32
h-auto
lg:h-auto
`;

const StyledLogo = tw.img`
w-28
lg:w-32
h-auto
lg:h-auto
`;

const SocialNetworksContainer = tw.div`
  flex
  justify-between
`;

const SocialNetwork = tw.a`
  lg:ml-8
  my-6
  max-w-[20px]
`;
