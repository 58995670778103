import React from "react";
import tw from "tailwind-styled-components";
import { PreFooterSectionItem } from "../../../types";
import { PageVariables } from "../../../services/pageVariables";
import { textCompiler } from "../../../services/textCompiler";

interface PreFooterSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: PreFooterSectionItem[];
}

interface PreFooterProps {
  title: string;
  items: PreFooterSectionItem[];
  variables?: PageVariables;
  isFullScreen?: boolean;
}

function PreFooter(props: PreFooterProps): JSX.Element {
  const { title, items, variables } = props;

  return (
    <Row>
      <Title>{textCompiler(title, variables)}</Title>
      <LinksContainer>
        {items.map((item, i) => (
          <Link key={i} href={item.url}>
            {textCompiler(`{type} à ${item.name}`, variables)}
          </Link>
        ))}
      </LinksContainer>
    </Row>
  );
}

export function PreFooterSingleSection(
  props: PreFooterSectionProps
): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <div className="max-w-screen-2xl mx-auto lg:px-8">
        <PreFooter
          title={"Recherches fréquentes pour {type}"}
          items={data}
          variables={variables}
        />
      </div>
    </Main>
  );
}

const Main = tw.div`
px-6
pb-10
lg:pb-0
`;

const Row = tw.div`
  py-8
  border-t
  col-span-1
`;

const Title = tw.p`
  text-base
`;

const LinksContainer = tw.div`
  grid
  lg:grid-cols-4
  grid-cols-1
`;

const Link = tw.a`
  text-sm
  mt-4
  text-darkGrey
  hover:underline 
  hover:text-blue
`;
