/* eslint-disable indent */
import { v4 as uuidv4 } from "uuid";
import {
  CardsSection,
  ContactUsSection,
  EcologySection,
  FoldableSection,
  FooterSection,
  ImageBannerSection,
  LargeCardsSection,
  LinksSection,
  LogosSection,
  MapsCardSection,
  MobileAppBanner,
  NavigationBar,
  NewsLetterBanner,
  PreFooterSection,
  PreFooterSingleSection,
  RatingsSection,
  Section404,
  SimpleSection,
  TitleSection,
  TransfertsSection,
  TwoColorColumnsSection,
} from "../containers/components";
import { ContentsSection } from "../containers/components/Sections/ContentsSection";
import DatasSection from "../containers/components/Sections/DatasSection";
import { RSETitleSection } from "../containers/components/Sections/RSETitlesSection";
import { TestimonialsSection } from "../containers/components/Sections/TestimonialsSection";
import {
  CardsSectionType,
  ContactUsSectionType,
  ContentSectionType,
  EcologyBannerType,
  FoldableSectionType,
  FooterSectionType,
  ImageBannerSectionType,
  LargeCardsSectionType,
  LinksSectionType,
  LogosSectionType,
  MapsCardSectionType,
  MobileAppBannerType,
  NavigationBarType,
  NewsLetterBannerType,
  PageSectionType,
  PreFooters,
  PreFooterSectionItem,
  RatingsSectionType,
  RSETitleSectionType,
  SectionsType,
  SectionTypeEnum,
  SimplesSectionType,
  TitlesSectionType,
  TransfertsSectionType,
  TwoColorColumnsSectionType,
} from "../types";
import { PageVariables } from "./pageVariables";

export function renderSection(
  section: PageSectionType<SectionsType> | null,
  variables?: PageVariables,
  isFullScreen?: boolean,
  hideHeader?: boolean,
  additionalCta?: boolean,
  overrideLogoUrl?: string,
  firstSection?: boolean
) {
  if (!section) {
    return <></>;
  }
  switch (section.name) {
    case SectionTypeEnum.CARDS_SECTION:
      return (
        <CardsSection
          key={section.id}
          variables={variables}
          data={section.section as CardsSectionType}
          isFullScreen={isFullScreen}
          additionalCta={additionalCta}
        />
      );
    case SectionTypeEnum.DATAS_SECTION:
      return (
        <DatasSection
          key={section.id}
          variables={variables}
          data={section.section as any}
          isFullScreen={isFullScreen}
          additionalCta={additionalCta}
        />
      );
    case SectionTypeEnum.CONTENTS_SECTION:
      return (
        <ContentsSection
          key={section.id}
          variables={variables}
          data={section.section as ContentSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.LINKS_SECTION:
      return (
        <LinksSection
          key={section.id}
          variables={variables}
          data={section.section as LinksSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.LOGOS_SECTION:
      return (
        <LogosSection
          key={section.id}
          variables={variables}
          data={section.section as LogosSectionType}
          isFullScreen={isFullScreen}
          additionalCta={additionalCta}
        />
      );
    case SectionTypeEnum.RATINGS_SECTION:
      return (section.section as RatingsSectionType).carousel ? (
        <TestimonialsSection
          key={section.id}
          variables={variables}
          data={section.section as RatingsSectionType}
          isFullScreen={isFullScreen}
        />
      ) : (
        <RatingsSection
          key={section.id}
          variables={variables}
          data={section.section as RatingsSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.SIMPLES_SECTION:
      return (
        <SimpleSection
          key={section.id}
          variables={variables}
          data={section.section as SimplesSectionType}
          isFullScreen={isFullScreen}
          hideHeader={hideHeader}
          firstSection={firstSection}
        />
      );
    case SectionTypeEnum.MOBILE_APP_BANNER:
      return (
        <MobileAppBanner
          key={section.id}
          variables={variables}
          data={section.section as MobileAppBannerType}
        />
      );
    case SectionTypeEnum.NAVIGATION_BAR:
      return (
        <NavigationBar
          key={section.id}
          variables={variables}
          data={section.section as NavigationBarType}
          isFullScreen={isFullScreen}
          overrideLogoUrl={overrideLogoUrl}
        />
      );
    case SectionTypeEnum.TITLES_SECTION:
      return (
        <TitleSection
          key={section.id}
          variables={variables}
          data={section.section as TitlesSectionType}
          isFullScreen={isFullScreen}
          additionalCta={additionalCta}
        />
      );
    case SectionTypeEnum.PRE_FOOTER:
      return (
        <PreFooterSection
          key={section.id}
          variables={variables}
          data={section.section as PreFooters}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.PRE_FOOTER_SINGLE:
      return (
        <PreFooterSingleSection
          key={section.id}
          variables={variables}
          data={section.section as PreFooterSectionItem[]}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.FOOTER:
      return (
        <FooterSection
          key={section.id}
          variables={variables}
          data={section.section as FooterSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.ECOLOGY_BANNER:
      return (
        <EcologySection
          key={section.id}
          variables={variables}
          data={section.section as EcologyBannerType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.NEWSLETTERS_BANNER:
      return (
        <NewsLetterBanner
          key={section.id}
          variables={variables}
          data={section.section as NewsLetterBannerType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.MAPS_CARD_SECTION:
      return (
        <MapsCardSection
          key={section.id}
          variables={variables}
          data={section.section as MapsCardSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.IMAGE_BANNERS:
      return (
        <ImageBannerSection
          key={section.id}
          variables={variables}
          data={section.section as ImageBannerSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.TRANSFERTS_SECTION:
      return (
        <TransfertsSection
          key={section.id}
          variables={variables}
          data={section.section as TransfertsSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.LARGE_CARDS_SECTION:
      return (
        <LargeCardsSection
          key={section.id}
          variables={variables}
          data={section.section as LargeCardsSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.FOLDABLE_SECTION:
      return (
        <FoldableSection
          key={section.id}
          variables={variables}
          data={section.section as FoldableSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.TWO_COLOR_COLUMNS_SECTION:
      return (
        <TwoColorColumnsSection
          key={section.id}
          variables={variables}
          data={section.section as TwoColorColumnsSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.RSE_TITLES_SECTION:
      return (
        <RSETitleSection
          key={section.id}
          variables={variables}
          data={section.section as RSETitleSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.CONTACT_US:
      return (
        <ContactUsSection
          key={section.id}
          variables={variables}
          data={section.section as ContactUsSectionType}
          isFullScreen={isFullScreen}
        />
      );
    case SectionTypeEnum.SECTION_404:
      return <Section404 key={"page-404"} />;
    default:
      break;
  }
  return <></>;
}

export function generatePreFooterType(
  data: PreFooters
): PageSectionType<PreFooters> {
  return {
    id: uuidv4(),
    name: SectionTypeEnum.PRE_FOOTER,
    section: data,
  };
}

export function generatePreFooterSingleType(
  data: PreFooterSectionItem[]
): PageSectionType<PreFooterSectionItem[]> {
  return {
    id: uuidv4(),
    name: SectionTypeEnum.PRE_FOOTER_SINGLE,
    section: data,
  };
}
