import React from 'react';
import tw from 'tailwind-styled-components';

interface LogoProp extends React.ImgHTMLAttributes<HTMLImageElement> {}

export function Logo(props: LogoProp): JSX.Element {
  return <ImageStyled {...props} />;
}

const ImageStyled = tw.img`
  lg:w-44
  lg:h-24
  w-full
  h-44
`;
