import React, { useState } from 'react';
import tw from 'tailwind-styled-components';
import { HeaderSection, Select } from '../../../components';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { MapsCard, MapsCardSectionType, MeetupPoint } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';
import { Image } from '../../../components';

interface MapsCardSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: MapsCardSectionType;
}

export function MapsCardSection(props: MapsCardSectionProps): JSX.Element {
  const { data, variables } = props;

  const [meetupPoint, setMeetupPoint] = useState<MeetupPoint | null>(
    findFirstMeetupPoint(data?.cards)
  );
  const [meetupPointCardIndex, setMeetupPointCardIndex] = useState<
    number | null
  >(findFirstMeetupPointCard(data?.cards));

  function findFirstMeetupPoint(cards: MapsCard[]): MeetupPoint | null {
    // eslint-disable-next-line no-loops/no-loops
    for (const card of cards) {
      if (card.meetupPoints && card.meetupPoints.length > 0) {
        return card.meetupPoints[0];
      }
    }
    return null;
  }

  function findFirstMeetupPointCard(cards: MapsCard[]): number | null {
    // eslint-disable-next-line no-loops/no-loops
    const index = cards.findIndex(
      (card) => card.meetupPoints && card.meetupPoints.length > 0
    );
    return index === -1 ? null : index;
  }

  return (
    <Main>
      <HeaderSection
        title={textCompiler(data.title, variables)}
        text={textCompiler(data.text, variables)}
      />
      <CardsContainer>
        {data.cards.map((card: MapsCard, index: number) => {
          return (
            <Card key={card.id}>
              <ImageStyled
                src={getStrapiMediaUrl(card.image.data.attributes)}
                alt={card.image.data.attributes.name}
              />
              <CardTitle>{textCompiler(card.title, variables)}</CardTitle>
              <CardText>{textCompiler(card.text ?? '', variables)}</CardText>
              {card?.meetupPoints && card?.meetupPoints.length > 0 && (
                <Select
                  className='mb-4'
                  onChange={(e) => {
                    if (card.meetupPoints) {
                      setMeetupPoint(
                        card.meetupPoints.find(
                          (m) => m.name === e.target.value
                        ) ?? card.meetupPoints[0]
                      );
                    }
                  }}
                  placeholder={card.meetupPoints[0].name}
                  options={card.meetupPoints.map((point) => {
                    return { value: point.name, label: point.name };
                  })}
                />
              )}
              {meetupPoint && meetupPointCardIndex === index && (
                <FindDriverContainer>
                  <PinStyled src={'/pin.svg'} alt={'pin'} />
                  <CardText>{meetupPoint.place}</CardText>
                </FindDriverContainer>
              )}
            </Card>
          );
        })}
      </CardsContainer>
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lg:my-16
  lm:px-0 
`;

const CardsContainer = tw.div`
  grid
  lg:grid-cols-2
  gap-4
`;

const Card = tw.div`
  bg-lightGrey
  flex-col
  flex
  lg:p-6
  px-4
  lg:py-10

`;

const ImageStyled = tw(Image)`
  h-auto
`;

const CardTitle = tw.h2`
  text-lg
  font-bold
  my-1
`;

const CardText = tw.p`
  text-sm
  mb-4
`;

const FindDriverContainer = tw.div`
  flex 
  w-full
  align-middle
`;

const PinStyled = tw.img`
  mr-2
  h-6
  w-6
`;
