import { StrapiMedia } from "./StrapiMedia";

export interface RatingsSectionType {
	title: string;
	bgColor: string;
	subtitle: string;
	ratings: RatingsSectionRating[];
	carousel?: boolean;
}

export interface RatingsSectionRating {
	text: string;
	author: string;
	image: { data: StrapiMedia };
	type: RatingType;
}

export enum RatingType {
	APP_STORE = "App Store",
	PLAY_STORE = "Play Store",
	GOOGLE = "Google",
}
