import tw from "tailwind-styled-components";
import { Image, RealButton } from "../../../components";
import { isHexColor } from "../../../services/colors";
import { convertPxToRem } from "../../../services/convertTo";
import { PageVariables } from "../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { textCompiler } from "../../../services/textCompiler";
import { TitlesSectionType } from "../../../types";

interface TitleSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: TitlesSectionType;
  additionalCta?: boolean;
}

export function TitleSection(props: TitleSectionProps): JSX.Element {
  const { data, variables, additionalCta = false } = props;
  const title = textCompiler(data.title, variables);
  const titleSize = data.titleSize
    ? `${convertPxToRem(data.titleSize)}rem`
    : "3.5rem";
  const text = textCompiler(data.text, variables);
  const textSize = data.textSize
    ? `${convertPxToRem(data.textSize)}rem`
    : "1.25rem";
  const bgColor = isHexColor(data.bgColor, "#0D8261");

  return (
    <Main style={{ backgroundColor: bgColor }}>
      <ColText>
        <Title
          style={{
            fontSize: titleSize,
          }}
        >
          {title}
        </Title>
        <Text
          style={{
            fontSize: textSize,
          }}
        >
          {text}
        </Text>
        {additionalCta && (
          <RealButton
            onClick={() =>
              window.open(
                data.cta?.destination,
                data.cta?.newWindow ? "_blank" : ""
              )
            }
            color={data.cta?.bgColor}
            className="mt-12 w-80"
          >
            {data.cta?.label}
          </RealButton>
        )}
        {data.logos?.data?.length > 0 && (
          <LogosWrapper>
            {data.logos.data.map((logo, i) => (
              <Image
                key={`LogosWrapper-Image-${i}`}
                className="h-24 w-24"
                src={getStrapiMediaUrl(logo.attributes)}
                alt="logo"
              />
            ))}
          </LogosWrapper>
        )}
      </ColText>
      <Col1>
        {data.image && data.image.data && (
          <Image
            src={getStrapiMediaUrl(data.image.data.attributes)}
            alt="title"
          />
        )}
      </Col1>
    </Main>
  );
}

const LogosWrapper = tw.div`
	flex
	flex-row
	gap-3
	mt-8
`;

const Main = tw.div`
  w-full 
  grid 
  grid-cols-1 
  lg:grid-cols-2
`;

const ColText = tw.div`
col-span-1
text-white
grid
content-center
py-32
order-1
lg:order-1
px-4
lg:px-20
`;

const Col1 = tw.div`
col-span-1
order-2
lg:order-2
`;

const Title = tw.h1`
font-bold
leading-[4rem]
mb-8
break-words

`;

const Text = tw.p`
leading-8
`;
