import router from 'next/router';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import tw from 'tailwind-styled-components';
import { PageArticleType } from '../../../types';

interface BlogSearchBarProps {
  className?: string;
  articles: PageArticleType[];
}

export function BlogSearchBar(props: BlogSearchBarProps): JSX.Element {
  const { className } = props;
  const ref: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement | null>(null);

  const [search, setSearch] = useState('');
  const [results, setResults] = useState<PageArticleType[]>([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    function onClickOutside() {
      setShowResults(false);
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (!event?.target) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const filtered = props.articles.filter((article) => {
      return article.title.toLowerCase().includes(search.toLowerCase());
    });
    if (filtered.length > 0) setShowResults(true);
    else setShowResults(false);
    if (search.length === 0) {
      setResults([]);
      return;
    }
    setResults(filtered);
  }, [search]);

  return (
    <Main className={className} ref={ref}>
      <SearchBar
        placeholder='Rechercher'
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      {showResults && (
        <ResultsContainer>
          {results.map((article, i) => (
            <Result
              key={i}
              onClick={() => router.push(`/blog/article/${article.slug}`)}
            >
              {article.title}
            </Result>
          ))}
        </ResultsContainer>
      )}
    </Main>
  );
}

const Main = tw.div`
  w-72
  relative
`;

const SearchBar = tw.input`
  w-full
  px-2
  py-3
  bg-gray-200
  text-gray-700
  outline-none
  rounded
  placeholder-gray-400
`;

const ResultsContainer = tw.div`
  flex
  flex-col
  absolute
  top-12
  w-full
  rounded
  bg-white
  border
  border-gray-300
`;

const Result = tw.div`
  py-4
  px-2
  cursor-pointer
  border-b
  last:border-b-0
  border-gray-300
  hover:bg-gray-50
`;
