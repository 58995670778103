import removeMarkdown from 'markdown-to-text';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { formatFrenchFullDate } from '../../../services/blog';
import { getStrapiMediaUrl } from '../../../services/strapiApi';
import { capitalize } from '../../../services/utils';
import { PageArticleType } from '../../../types';

export function ArticleCard(props: {
  className?: string;
  hideCoverImage?: boolean;
  hidePreview?: boolean;
  article: PageArticleType;
  isLarge?: boolean;
  onClick: () => void;
}): JSX.Element {
  const { article, onClick, isLarge, className, hideCoverImage, hidePreview } =
    props;
  return (
    <ArticleCardContainer onClick={onClick} className={className}>
      {!hideCoverImage && (
        <ArticleCardImageContainer>
          {article?.coverImage?.data.attributes && (
            <ArticleCardImage
              src={getStrapiMediaUrl(article.coverImage.data.attributes)}
            />
          )}
        </ArticleCardImageContainer>
      )}
      <ArticleCardTitle $isLarge={!!isLarge}>
        {(article?.title?.length > 60
          ? article.title.slice(0, 60) + '...'
          : article.title) ?? ''}
      </ArticleCardTitle>
      <ArticleDate>
        {article?.date
          ? capitalize(formatFrenchFullDate(new Date(article.date)))
          : ''}
        {' · '}
        {article.author ?? ''}
      </ArticleDate>
      {!hidePreview && (
        <ArticlePreview
          dangerouslySetInnerHTML={{
            __html:
              removeMarkdown(article?.content ?? '').slice(
                0,
                hideCoverImage ? 120 : 250
              ) + '...',
          }}
        ></ArticlePreview>
      )}
    </ArticleCardContainer>
  );
}

const ArticleCardContainer = tw.div`
  col-span-1
  flex
  flex-col
  w-full
  cursor-pointer
  rounded
  overflow-hidden
  shadow-md
  hover:shadow-xl
  hover:scale-105
  transition
  bg-white
`;

const ArticleCardTitle = tw.p<{ $isLarge: boolean }>`
  text-smallTitle
  mt-4
  px-4
  2xl:h-26 
  md:h-12 
  sm:h-22 
  h-34
   ${(props: { $isLarge: boolean }) => (props.$isLarge ? 'lg:h-22' : 'lg:h-44')}
`;

const ArticleDate = tw.p`
  text-xs
  text-orange-400
  mb-6
  px-4
`;

const BlueLinks = styled.p`
  a {
    color: #0000ee;
  }
`;

const ArticlePreview = tw(BlueLinks)`
  text-sm
  text-gray-500
  px-4
  pb-4
`;

const ArticleCardImageContainer = tw.div`
  w-full
  h-60
  2xl:h-40
`;

const ArticleCardImage = tw.img`
  object-cover
  bg-contain
  bg-center
  w-full
  h-full
`;
